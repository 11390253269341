import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import { Home } from "./pages/home";
import { BookDemo } from "./pages/book-demo";
import { Plan } from "./pages/plan";
import { AboutUs } from "./pages/about-us";
import { PdfVerification } from "./pages/pdf-verification";
import { UploadResume } from "./pages/upload-resume";

const RoutesList = () => {
  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="book-demo" element={<BookDemo />} />
        <Route path="plan" element={<Plan />} />
        <Route path="pdf-verification/:id" element={<PdfVerification />} />
        <Route path="upload-resume" element={<UploadResume />} />
      </Routes>
    </Fragment>
  );
};

export default RoutesList;
