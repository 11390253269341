import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ListItemButton, Collapse, styled, LinearProgress,IconButton } from "@mui/material";
import pdfview from "../assets/images/doc.png";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import SplitscreenOutlinedIcon from "@mui/icons-material/SplitscreenOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"; 
import host from "../host";
import PageLoader from "../components/page-loader";
import PdfContent from "../components/pdf-content";
import PdfDetails from "../components/pdf-details";
import CloseIcon from "@mui/icons-material/Close";
import { PdfViewer } from "react-pdf-selection";
import logoicon from '../assets/images/logo-icon.png';
import SortIcon from '@mui/icons-material/Sort';
import ClearIcon from '@mui/icons-material/Clear';
// import NoDataCase from '../components/nodatacase';
export const PdfVerification = () => {
const BorderLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
  height: 30,
  borderRadius: 10,
  position: "relative",    
  background: "linear-gradient(270deg, #2092EC, rgb(143, 200, 245) 100%)", // Track background gradient
  "& .MuiLinearProgress-bar": {
  background: "transparent", // Gradient for progress bar
  },
  "&::after": {
  content: '""',
  position: "absolute",
  top: 3,
  bottom: 0,
  borderRadius: "10px",
left: `${value}%`, // Dynamic progress position
width: "5px",
backgroundColor: "#fff", // Black vertical line
height:'83%'
},
}));
//this below function stepform start//
//this below function stepform start//
const [stepform2, setActiveTab2] = useState("Preview");
const handleTabClick2 = (tab) => {
setActiveTab2(tab);
};
const { id } = useParams();
const [loading, setLoading] = useState(true);
const [atsDetails, setAtsDetails] = useState(null);
const getAtsDetails = () => {
setLoading(true);
fetch(host.hostname + `/api/v1/public/demo/ats_analysis/${id}`, {
method: "GET",
headers: {
"Content-type": "application/json",
},
})
.then((res) => {
return res.json();
})
.then(async (data) => {
if (data.success) {
setLoading(false);
setAtsDetails(data);
}
})
.catch((err) => {
setLoading(false);
console.log(err);
});
};
useEffect(() => {
if (id) {
getAtsDetails();
}
}, [id]);
const sectionRefs = {
content: useRef(null),
format: useRef(null),
sections: useRef(null),
skills: useRef(null),
style: useRef(null),
};
const handleTabClick = (sectionKey) => {
sectionRefs[sectionKey]?.current?.scrollIntoView({ behavior: "smooth" });
};

const [showIssue, setShowIssue] = useState(false);
const toggleShowIssue = () => {
setShowIssue((prev) => !prev);
};
const [showIssueresume, setShowIssueresume] = useState(false); 
const toggleShowresume = () => {
setShowIssueresume((prev) => !prev);
};
return (
<>
 
{loading ? (
<PageLoader />
) : (
<div className="viewer-wrapper">
   {atsDetails && (
   <div className="viewer-flex">
      <div className="ats_content_wrapper" style={{ position: "relative" }}>
      <div className="viewer-body">
         <div className="top_analysis_box    ">
         <div className='chat_title  resume_top_title'>
               <h3 style={{display:'flex', alignItems:'center'}}>
               <div className='show_hide  '>
                  <div className='mobile_analysis_menu' style={{marginRight:'10px'}}>
                     <IconButton aria-label="delete" size="small" onClick={toggleShowIssue}>
                        <SortIcon   />
                     </IconButton>
                  </div>
               </div>
               <img src={logoicon} height={24} style={{marginRight:'7px'}} />
               SelectRight  Analysis
               </h3>
            </div>
            <div className="top_analysis_content"> 
               <div className="Competitive_score">
                  {atsDetails.atsData?.score && (
                  <>
                  <div className="mb-2 d-flex align-items-center justify-content-between" style={{ fontSize: "12px" }}>
                  <div className="top_analysis_title">
                     <h4>
                        <TrackChangesIcon fontSize="small" />
                        ATS Score
                     </h4>
                  </div>
                  <div className="top_analysis_title">
                     <h4 style={{ fontWeight: "700", fontSize: "18px" }}>{atsDetails.atsData.score}/100</h4>
                  </div>
               </div>
               <BorderLinearProgress variant="determinate" value={atsDetails.atsData.score} />
               </>
               )}
               {atsDetails.atsData?.issues && (
               <span className="mt-1 d-block" style={{ fontSize: "12px" }}>
               {atsDetails.atsData?.issues} Issues
               </span>
               )}
            </div>
         </div>
      </div>
      <div className="card-body">
         <div className="ats_flex">
            <div className={`score_aside ${showIssue ? 'show_issue' : ''}`} style={{ position: "relative" }}>
            <div className='show_hide  '>
               <div className='close_sidebar' onClick={toggleShowIssue} style={{marginRight:'10px'}}>
                  <IconButton aria-label="delete" size="small" >
                     <ClearIcon fontSize='small' />
                  </IconButton>
                  Close
               </div>
            </div>
            <div className="score_box  ">
               <div className="learning_growth_wrapper">
                  {Object.entries(atsDetails.atsData).map(([sectionKey, sectionValue], sectionIndex) => {
                  if (typeof sectionValue === "object" && sectionValue.percentage) {
                  return (
                  <div className="learning_growth_box" key={sectionKey} onClick={() =>
                     handleTabClick(sectionKey)}>
                     <div className="learning_growth_head">
                        <div className="learning_content">
                           <h4>{sectionKey.charAt(0).toUpperCase() + sectionKey.slice(1)}</h4>
                        </div>
                        <span className={`badge bg-inverse-${sectionValue.percentage > 70 ? "success" : sectionValue.percentage > 30 ? "warning" : "danger"}`}>
                        {sectionValue.percentage}%
                        </span>
                     </div>
                     {sectionValue.subcategories && (
                     <div className="learning_growth_body">
                        {sectionValue.subcategories.map((subcategory, subIndex) => (
                        <ListItemButton key={subIndex} className="learning_growth_row" style={{ background: "#edf8fe" }}>
                        <h4>
                           {subcategory.detail.flag ? 
                           <CloseIcon fontSize="small" className="text-danger" />
                           : 
                           <TaskAltIcon fontSize="small" className="text-success" />
                           }
                           {subcategory.key}
                        </h4>
                        </ListItemButton>
                        ))}
                     </div>
                     )}
                  </div>
                  );
                  }
                  return null;
                  })}
               </div>
            </div>
         </div>
         <PdfContent pdfDetails={atsDetails} sectionRefs={sectionRefs} />
      </div>
   </div>
</div>
</div>
 
   <div className={`resume_wrapper ${showIssueresume ? 'show_issue_resume' : ''}`}>
   
         <div className="viewer-head  ">
        
            <div className="response_type">
               <ul>
                  <li>
                     <Link to="#" className={`tab   ${stepform2 === "Preview" ? "active" : ""}`} onClick={() => handleTabClick2("Preview")}>
                     <VisibilityOutlinedIcon fontSize="small" />
                     Preview{" "}
                     </Link>
                  </li>
                  <li>
                     <Link to="#" className={`tab ${stepform2 === "fetched" ? "active" : ""}`} onClick={() => handleTabClick2("fetched")}>
                     <SplitscreenOutlinedIcon fontSize="small" />
                     Fetched Data
                     </Link>
                  </li>
               </ul>
            </div>
            <div className='viewer-actions'>
      <div className='show_hide  '>
         <div className='close_sidebar'  onClick={toggleShowresume} style={{marginRight:'10px',backgroundColor:'transparent', padding:'0px'}}>  
         <IconButton aria-label="delete" size="small" >
            <ClearIcon fontSize='small' />
         </IconButton>
         Close
      </div>
   </div>
</div>


      </div>

      <div className="viewer-body">
      {stepform2 === "Preview" && (
      <>
      <div className="pdf_box" style={{ position: "relative" }}>
      <div className="pdf-viewr custom-body">
         {/* <iframe src={pdfview}   frameborder="0" allowfullscreen></iframe> */}
         {
         <PdfViewer url={atsDetails?.new_file_path} />
         }
      </div>
   </div>
   </>
   )}
   {stepform2 === "fetched" && 
   <PdfDetails pdfDetails={atsDetails.extractedData} />
   }
   </div>
 
</div>

</div>
)}

<div className='show_hide'>
   <div className='mobile_show_resume'>
      <button type='button' className='btn btn-blue' onClick={toggleShowresume}  >Show Resume</button>
   </div>
</div>
</div>

)}
</>
);
};