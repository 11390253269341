import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IconButton, Collapse, Stack, Chip, Accordion, AccordionSummary, Typography, AccordionDetails, Alert, AlertTitle } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import personalinfo from "../assets/images/fetch-doc/information.png";
import skills from "../assets/images/fetch-doc/skills.png";
import workexp from "../assets/images/fetch-doc/working-hours.png";
import education from "../assets/images/fetch-doc/education.png";
import project from "../assets/images/fetch-doc/project.png";
import Certificates from "../assets/images/fetch-doc/certificate.png";
import achivements from "../assets/images/fetch-doc/achievement.png";
import publications from "../assets/images/fetch-doc/publications.png";
import Volunteering from "../assets/images/fetch-doc/Volunteering.png";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
const PdfDetails = (props) => {
  const [openSections, setOpenSections] = useState([]); // Open all sections by default
  const [shadowSections, setShadowSections] = useState([]); // Tracks which sections have shadow
  const toggleSection = (section) => {
    setOpenSections((prev) => (prev.includes(section) ? prev.filter((s) => s !== section) : [...prev, section]));
    // Toggle shadow for the clicked section only
    setShadowSections((prev) => (prev.includes(section) ? prev.filter((s) => s !== section) : [...prev, section]));
  };
  const [pdfDetails, setPdfDetails] = useState(props.pdfDetails ? props.pdfDetails : null);

  useEffect(() => {
    if (props.pdfDetails) {
      setPdfDetails(props.pdfDetails);
    }
  }, [props.pdfDetails]);

  return (
    <div className="fetched_Data_box">
      <div className="custom-body">
        <div className={`resume_fatch_box ${shadowSections.includes(0) ? "shadow" : ""}`}>
          <div class="resume_fatch_head" onClick={() => toggleSection(0)}>
            <div className="resume_fatch_left">
              <div className="resume_icon">
                <img src={personalinfo} alt="" />
              </div>
              <h5>Personal Information</h5>
            </div>
            <IconButton aria-label="delete">
              <ExpandMoreIcon />
            </IconButton>
          </div>
          <div className="setting_box_body">
            <Collapse in={openSections.includes(0)}>
              <div className="resume_fatch_body">
                <div className="personal_info">
                  <div className="candidate-profile-detail">
                    <table className="table mb-0">
                      <tbody>
                        <tr>
                          <td className="fw-bold">Full Name :</td>
                          <td>{pdfDetails?.basicDetails?.bioInfo?.name || "N/A"}</td>
                        </tr>
                        <tr>
                          <td className="fw-bold">Email Address : </td>
                          <td>
                            <Link to={`mailto:${pdfDetails?.basicDetails?.bioInfo?.email}`}>{pdfDetails?.basicDetails?.bioInfo?.email || "N/A"}</Link>
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-bold">Gender : </td>
                          <td>{pdfDetails?.basicDetails?.bioInfo?.gender || "N/A"}</td>
                        </tr>
                        <tr>
                          <td className="fw-bold">Mobile Number : </td>
                          <td>{pdfDetails?.basicDetails?.bioInfo?.phoneNumber || "N/A"}</td>
                        </tr>
                        <tr>
                          <td className="fw-bold">Date of Birth : </td>
                          <td>{pdfDetails?.basicDetails?.bioInfo?.dob || "N/A"}</td>
                        </tr>
                        <tr>
                          <td className="fw-bold">Current Location : </td>
                          <td>{pdfDetails?.basicDetails?.bioInfo?.location || "N/A"}</td>
                        </tr>
                        <tr>
                          <td className="fw-bold">Languages: </td>
                          <td>
                            {pdfDetails?.basicDetails?.bioInfo?.languages
                              ? pdfDetails.basicDetails.bioInfo.languages.map((lang, index) => (
                                  <span key={index}>
                                    {lang}
                                    {index < pdfDetails.basicDetails.bioInfo.languages.length - 1 && " , "}
                                  </span>
                                ))
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-bold">Descriptive Words : </td>
                          <td>
                            {pdfDetails?.basicDetails?.bioInfo?.descriptiveWords
                              ? pdfDetails.basicDetails.bioInfo.descriptiveWords.map((word, index) => (
                                  <span key={index}>
                                    {word}
                                    {index < pdfDetails.basicDetails.bioInfo.descriptiveWords.length - 1 && " , "}
                                  </span>
                                ))
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-bold">Portfolio URL : </td>
                          <td>
                            {pdfDetails?.basicDetails?.bioInfo?.portfolioUrl ? <Link to={pdfDetails.basicDetails.bioInfo.portfolioUrl}>{pdfDetails.basicDetails.bioInfo.portfolioUrl}</Link> : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-bold">Linkedin URL : </td>
                          <td>
                            {pdfDetails?.basicDetails?.bioInfo?.linkedinUrl ? <Link to={pdfDetails.basicDetails.bioInfo.linkedinUrl}>{pdfDetails.basicDetails.bioInfo.linkedinUrl}</Link> : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-bold">Github URL : </td>
                          <td>{pdfDetails?.basicDetails?.bioInfo?.githubUrl ? <Link to={pdfDetails.basicDetails.bioInfo.githubUrl}>{pdfDetails.basicDetails.bioInfo.githubUrl}</Link> : "N/A"}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="bio mt-3">
                      <Alert icon={false} severity="success">
                        <AlertTitle className="d-flex align-items-center justify-content-between">
                          Bio
                          <div className="">
                            <button style={{ padding: "2px 5px", fontSize: "12px" }} type="button" className="btn btn-secondary">
                              <AutoAwesomeIcon style={{ fontSize: "12px" }} className="text-warning" />
                              Enhance
                            </button>
                          </div>
                        </AlertTitle>
                        <div className="">{pdfDetails?.basicDetails?.bioInfo?.bio || "No bio available"}</div>
                      </Alert>
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
        <div className={`resume_fatch_box ${shadowSections.includes(1) ? "shadow" : ""}`}>
          <div class="resume_fatch_head" onClick={() => toggleSection(1)}>
            <div className="resume_fatch_left">
              <div className="resume_icon">
                <img src={skills} />
              </div>
              <h5>Skills</h5>
            </div>
            <IconButton aria-label="delete">
              <ExpandMoreIcon />
            </IconButton>
          </div>
          <div className="setting_box_body">
            <Collapse in={openSections.includes(1)}>
              <div className="resume_fatch_body">
                <div className="flex-body">
                  <div className="summary-group-wrapper">
                    <div className="diffrent-skill">
                      {/* Technical Skills */}
                      <div className="form-skill">
                        <h6 className="mb-2">Technical Skills</h6>
                        <Stack className="Collaboratorname mt-2" direction="row" spacing={1} style={{ maxHeight: "inherit", overflow: "inherit", flexWrap: "wrap" }}>
                          {pdfDetails?.skills?.technicalSkills?.map((skill, index) => (
                            <Chip key={index} label={skill} style={{ margin: "4px" }} />
                          ))}
                        </Stack>
                      </div>

                      {/* Tools & Technologies */}
                      <div className="form-skill">
                        <h6 className="mb-2">Tools & Technologies</h6>
                        <Stack className="Collaboratorname mt-2" direction="row" spacing={1} style={{ maxHeight: "inherit", overflow: "inherit", flexWrap: "wrap" }}>
                          {pdfDetails?.skills?.toolsTech?.map((tool, index) => (
                            <Chip key={index} label={tool} style={{ margin: "4px" }} />
                          ))}
                        </Stack>
                      </div>

                      {/* Soft Skills */}
                      <div className="form-skill additionalskill">
                        <h6 className="mb-2">Soft Skills</h6>
                        <Stack className="Collaboratorname mt-2" direction="row" spacing={1} style={{ maxHeight: "inherit", overflow: "inherit", flexWrap: "wrap" }}>
                          {pdfDetails?.skills?.softSkills?.map((skill, index) => (
                            <Chip key={index} label={skill} style={{ margin: "4px" }} />
                          ))}
                        </Stack>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
        <div className={`resume_fatch_box ${shadowSections.includes(2) ? "shadow" : ""}`}>
          <div class="resume_fatch_head" onClick={() => toggleSection(2)}>
            <div className="resume_fatch_left">
              <div className="resume_icon">
                <img src={workexp} />
              </div>
              <h5>Work Experience</h5>
            </div>
            <IconButton aria-label="delete">
              <ExpandMoreIcon />
            </IconButton>
          </div>
          <div className="setting_box_body">
            <Collapse in={openSections.includes(2)}>
              <div className="resume_fatch_body">
                <div className="summary-group-wrapper">
                  <div className="candidatetest-row cv-collapes">
                    {pdfDetails?.workDetails?.work?.map((job, index) => (
                      <React.Fragment key={index}>
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}a-content`} id={`panel${index}a-header`}>
                            <Typography>
                              <div className="candidate-work-head">
                                <div className="srnum">{index + 1}</div>
                                <div className="work-head-content">
                                  <div className="company-name">
                                    <span>
                                      <b>{job.company}</b>
                                    </span>
                                  </div>
                                  <div className="workdate">
                                    <div className="date-cta">
                                      <label>Position</label>
                                      <span className="devider">-</span>
                                      <div className="data_value">
                                        {job.position}
                                        <span>
                                          {job.jobType && (
                                            <span>
                                              {" "}
                                              (<i className="las la-business-time"></i> {job.jobType})
                                            </span>
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="date-cta">
                                      <label>Location</label>
                                      <span className="devider">-</span>
                                      <div className="data_value">{job.location}</div>
                                    </div>
                                    <div className="date-cta">
                                      <label>From</label>
                                      <span className="devider">-</span>
                                      <div className="data_value">{`${job.startMonth}/${job.startYear}`}</div>
                                    </div>
                                    <div className="date-cta">
                                      <label>To</label>
                                      <span className="devider">-</span>
                                      <div className="data_value">{job.isCurrent ? "Present" : `${job.endMonth}/${job.endYear}`}</div>
                                    </div>
                                    {job.skills && (
                                      <div className="date-cta">
                                        <label>Skills</label>
                                        <span className="devider">-</span>
                                        <div className="data_value">
                                          {job.skills.map((skill, i) => (
                                            <span key={i}>
                                              <b>{skill}</b>
                                              {i < job.skills.length - 1 && <span>,</span>}
                                            </span>
                                          ))}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              <div className="cv-card-wrapper">
                                <div className="card-row">
                                  <div className="card-content">
                                    <div className="company">
                                      <span>
                                        <b>Job Summary</b>
                                      </span>
                                      {job.description?.map((desc, i) => (
                                        <div key={i} style={{ marginTop: "8px" }}>
                                          {desc}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>                        
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
        <div className={`resume_fatch_box ${shadowSections.includes(3) ? "shadow" : ""}`}>
          <div className="resume_fatch_head" onClick={() => toggleSection(3)}>
            <div className="resume_fatch_left">
              <div className="resume_icon">
                <img src={education} alt="" />
              </div>
              <h5>Education</h5>
            </div>
            <IconButton aria-label="delete">
              <ExpandMoreIcon />
            </IconButton>
          </div>
          <div className="setting_box_body">
            <Collapse in={openSections.includes(3)}>
              <div className="resume_fatch_body">
                <div className="summary-group-wrapper">
                  <div className="candidatetest-row cv-collapes">
                    <div className="education-wrapper">
                      {pdfDetails?.basicDetails?.institute?.map((edu, index) => (
                        <React.Fragment key={index}>
                          <div className="candidate-work-head">
                            <div className="srnum">{index + 1}</div>
                            <div className="work-head-content">
                              <div className="company-name">
                                <span>
                                  <b>{edu.university}</b>
                                </span>
                              </div>
                              <div className="educationdetail workdate">
                                <div className="date-cta">
                                  <label>Degree</label>
                                  <span className="devider">-</span>
                                  <div className="data_value">{edu.degree || "N/A"}</div>
                                </div>
                                <div className="date-cta">
                                  <label>Specialization</label>
                                  <span className="devider">-</span>
                                  <div className="data_value">{edu.specialization || "N/A"}</div>
                                </div>
                                <div className="date-cta">
                                  <label>Location</label>
                                  <span className="devider">-</span>
                                  <div className="data_value">{edu.location || "N/A"}</div>
                                </div>
                                <div className="date-cta">
                                  <label>From</label>
                                  <span className="devider">-</span>
                                  <div className="data_value">
                                    {edu.batchStartMonth ? `${edu.batchStartMonth}/` : ""}
                                    {edu.batchStartYear}
                                  </div>
                                </div>
                                <div className="date-cta">
                                  <label>To</label>
                                  <span className="devider">-</span>
                                  <div className="data_value">
                                    {edu.batchEndMonth ? `${edu.batchEndMonth}/` : ""}
                                    {edu.batchEndYear}
                                  </div>
                                </div>
                                {edu.grade && (
                                  <div className="date-cta">
                                    <label>Grade</label>
                                    <span className="devider">-</span>
                                    <div className="data_value">{edu.grade}</div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>                          
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
        <div className={`resume_fatch_box ${shadowSections.includes(4) ? "shadow" : ""}`}>
          <div className="resume_fatch_head" onClick={() => toggleSection(4)}>
            <div className="resume_fatch_left">
              <div className="resume_icon">
                <img src={project} alt="" />
              </div>
              <h5>Project Experience</h5>
            </div>
            <IconButton aria-label="delete">
              <ExpandMoreIcon />
            </IconButton>
          </div>
          <div className="setting_box_body">
            <Collapse in={openSections.includes(4)}>
              <div className="resume_fatch_body">
                <div className="summary-group-wrapper">
                  <div className="candidatetest-row cv-collapes">
                    {pdfDetails?.projectDetails?.map((project, index) => (
                      <React.Fragment key={index}>
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}a-content`} id={`panel${index}a-header`}>
                            <Typography>
                              <div className="candidate-work-head">
                                <div className="srnum">{index + 1}</div>
                                <div className="work-head-content">
                                  <div className="company-name mb-0">
                                    <span>
                                      <b>{project.name}</b>
                                    </span>
                                  </div>
                                  <div className="workdate">
                                    {project.startMonth && project.startYear && (
                                      <div className="date-cta">
                                        <label>From</label>
                                        <span className="devider">-</span>
                                        <div className="data_value">{`${project.startMonth}/${project.startYear}`}</div>
                                      </div>
                                    )}
                                    {(project.endMonth && project.endYear) ||
                                      (project.isCurrent && (
                                        <div className="date-cta">
                                          <label>To</label>
                                          <span className="devider">-</span>
                                          <div className="data_value">{project.isCurrent ? "Present" : `${project.endMonth}/${project.endYear}`}</div>
                                        </div>
                                      ))}
                                    {project.skills && (
                                      <div className="date-cta">
                                        <label>Skills</label>
                                        <span className="devider">-</span>
                                        <div className="data_value">
                                          {project.skills.map((skill, i) => (
                                            <span key={i}>
                                              <b>{skill}</b>
                                              {i < project.skills.length - 1 && <span>,</span>}
                                            </span>
                                          ))}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              <div className="cv-card-wrapper">
                                <div className="card-row">
                                  <div className="card-content">
                                    <div className="company">
                                      <span>
                                        <b>Project Summary</b>
                                      </span>
                                      {project.description?.map((desc, i) => (
                                        <div key={i} style={{ marginTop: "8px" }}>
                                          {desc}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
        <div className={`resume_fatch_box ${shadowSections.includes(5) ? "shadow" : ""}`}>
          <div className="resume_fatch_head" onClick={() => toggleSection(5)}>
            <div className="resume_fatch_left">
              <div className="resume_icon">
                <img src={Certificates} alt="" />
              </div>
              <h5>Certificates</h5>
            </div>
            <IconButton aria-label="delete">
              <ExpandMoreIcon />
            </IconButton>
          </div>
          <div className="setting_box_body">
            <Collapse in={openSections.includes(5)}>
              <div className="resume_fatch_body">
                <div className="summary-group-wrapper">
                  <div className="candidatetest-row cv-collapes">
                    {pdfDetails?.certifications?.certificates?.map((certificate, index) => (
                      <Accordion key={index}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}a-content`} id={`panel${index}a-header`}>
                          <Typography>
                            <div className="candidate-work-head">
                              <div className="srnum">{index + 1}</div>
                              <div className="work-head-content">
                                <div className="company-name">
                                  <span>
                                    <b>{certificate.name}</b>
                                  </span>
                                </div>
                                <div className="workdate">
                                  {certificate.issuedBy && (
                                    <div className="date-cta">
                                      <label>Issued By</label>
                                      <span className="devider">-</span>
                                      <div className="data_value">{certificate.issuedBy}</div>
                                    </div>
                                  )}
                                  {certificate.issuedOn && (
                                    <div className="date-cta">
                                      <label>Issued On</label>
                                      <span className="devider">-</span>
                                      <div className="data_value">{certificate.issuedOn}</div>
                                    </div>
                                  )}
                                  {certificate.expiresOn && (
                                    <div className="date-cta">
                                      <label>Expires On</label>
                                      <span className="devider">-</span>
                                      <div className="data_value">{certificate.expiresOn}</div>
                                    </div>
                                  )}
                                  {certificate.skills && (
                                    <div className="date-cta">
                                      <label>Skills</label>
                                      <span className="devider">-</span>
                                      <div className="data_value">
                                        {certificate.skills.map((skill, i) => (
                                          <span key={i}>
                                            <b>{skill}</b>
                                            {i < certificate.skills.length - 1 && <span>,</span>}
                                          </span>
                                        ))}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Typography>
                        </AccordionSummary>
                        {certificate.description && (
                          <AccordionDetails>
                            <Typography>
                              <div className="cv-card-wrapper">
                                <div className="card-row">
                                  <div className="card-content">
                                    <div className="company">
                                      <span>{certificate.description}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Typography>
                          </AccordionDetails>
                        )}
                      </Accordion>
                    ))}
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
        <div className={`resume_fatch_box ${shadowSections.includes(6) ? "shadow" : ""}`}>
          <div className="resume_fatch_head" onClick={() => toggleSection(6)}>
            <div className="resume_fatch_left">
              <div className="resume_icon">
                <img src={achivements} alt="" />
              </div>
              <h5>Achievements</h5>
            </div>
            <IconButton aria-label="delete">
              <ExpandMoreIcon />
            </IconButton>
          </div>
          <div className="setting_box_body">
            <Collapse in={openSections.includes(6)}>
              <div className="resume_fatch_body">
                <div className="summary-group-wrapper">
                  <div className="candidatetest-row cv-collapes">
                    {pdfDetails?.achievements?.map((achievement, index) => (
                      <Accordion key={index}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}a-content`} id={`panel${index}a-header`}>
                          <Typography>
                            <div className="candidate-work-head">
                              <div className="srnum">{index + 1}</div>
                              <div className="work-head-content">
                                <div className="company-name">
                                  <span>
                                    <b>{achievement.title}</b>
                                  </span>
                                </div>
                                <div className="workdate">
                                  {achievement.date && (
                                    <div className="date-cta">
                                      <label>Date</label>
                                      <span className="devider">-</span>
                                      <div className="data_value">{achievement.date}</div>
                                    </div>
                                  )}
                                  {achievement.skills && achievement.skills.length > 0 && (
                                    <div className="date-cta">
                                      <label>Skills</label>
                                      <span className="devider">-</span>
                                      <div className="data_value">
                                        {achievement.skills.map((skill, i) => (
                                          <span key={i}>
                                            <b>{skill}</b>
                                            {i < achievement.skills.length - 1 && <span>,</span>}
                                          </span>
                                        ))}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <div className="cv-card-wrapper">
                              <div className="card-row">
                                <div className="card-content">
                                  <div className="company">
                                    {achievement.description?.map((desc, i) => (
                                      <span key={i}>{desc}</span>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
        <div className={`resume_fatch_box ${shadowSections.includes(7) ? "shadow" : ""}`}>
          <div className="resume_fatch_head" onClick={() => toggleSection(7)}>
            <div className="resume_fatch_left">
              <div className="resume_icon">
                <img src={publications} alt="" />
              </div>
              <h5>Publications</h5>
            </div>
            <IconButton aria-label="delete">
              <ExpandMoreIcon />
            </IconButton>
          </div>
          <div className="setting_box_body">
            <Collapse in={openSections.includes(7)}>
              <div className="resume_fatch_body">
                <div className="summary-group-wrapper">
                  <div className="candidatetest-row cv-collapes">
                    {pdfDetails?.certifications?.publications?.map((publication, index) => (
                      <Accordion key={index}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}a-content`} id={`panel${index}a-header`}>
                          <Typography>
                            <div className="candidate-work-head">
                              <div className="srnum">{index + 1}</div>
                              <div className="work-head-content">
                                <div className="company-name">
                                  <span>
                                    <b>{publication.title}</b>
                                  </span>
                                </div>
                                {publication.date && (
                                  <div className="workdate">
                                    <div className="date-cta">
                                      <label>Date</label>
                                      <span className="devider">-</span>
                                      <div className="data_value">{publication.date}</div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </Typography>
                        </AccordionSummary>
                        {publication.description && (
                          <AccordionDetails>
                            <Typography>
                              <div className="cv-card-wrapper">
                                <div className="card-row">
                                  <div className="card-content">
                                    <div className="company">
                                      <span>{publication.description}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Typography>
                          </AccordionDetails>
                        )}
                      </Accordion>
                    ))}
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
        <div className={`resume_fatch_box ${shadowSections.includes(8) ? "shadow" : ""}`}>
          <div className="resume_fatch_head" onClick={() => toggleSection(8)}>
            <div className="resume_fatch_left">
              <div className="resume_icon">
                <img src={Volunteering} alt="" />
              </div>
              <h5>Volunteering</h5>
            </div>
            <IconButton aria-label="delete">
              <ExpandMoreIcon />
            </IconButton>
          </div>
          <div className="setting_box_body">
            <Collapse in={openSections.includes(8)}>
              <div className="resume_fatch_body">
                <div className="summary-group-wrapper">
                  <div className="candidatetest-row cv-collapes">
                    {pdfDetails?.extracurricular?.map((activity, index) => (
                      <Accordion key={index}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}a-content`} id={`panel${index}a-header`}>
                          <Typography>
                            <div className="candidate-work-head">
                              <div className="srnum">{index + 1}</div>
                              <div className="work-head-content">
                                <div className="company-name">
                                  <span>
                                    <b>{activity.title}</b>
                                  </span>
                                </div>
                                <div className="workdate">
                                  {activity.orgAssociated && (
                                    <div className="date-cta">
                                      <label>Org Associated</label>
                                      <span className="devider">-</span>
                                      <div className="data_value">{activity.orgAssociated}</div>
                                    </div>
                                  )}
                                  {activity.position && (
                                    <div className="date-cta">
                                      <label>Position</label>
                                      <span className="devider">-</span>
                                      <div className="data_value">{activity.position}</div>
                                    </div>
                                  )}
                                  {activity.startMonth && activity.startYear && (
                                    <div className="date-cta">
                                      <label>From</label>
                                      <span className="devider">-</span>
                                      <div className="data_value">{`${activity.startMonth}/${activity.startYear}`}</div>
                                    </div>
                                  )}
                                  {(activity.endMonth && activity.endYear) || activity.isCurrent ? (
                                    <div className="date-cta">
                                      <label>To</label>
                                      <span className="devider">-</span>
                                      <div className="data_value">{activity.isCurrent ? "Present" : `${activity.endMonth}/${activity.endYear}`}</div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </Typography>
                        </AccordionSummary>
                        {activity.description && (
                          <AccordionDetails>
                            <Typography>
                              <div className="cv-card-wrapper">
                                <div className="card-row">
                                  <div className="card-content">
                                    <div className="company">
                                      {activity.description.map((desc, i) => (
                                        <span key={i}>{desc}</span>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Typography>
                          </AccordionDetails>
                        )}
                      </Accordion>
                    ))}
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PdfDetails;
