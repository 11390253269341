import React, { useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
const PdfContent = ({ pdfDetails, sectionRefs }) => {
  const [expandedStates, setExpandedStates] = useState({}); // State to track expanded panels for each subcategory

  const handleAccordionChange = (subcategoryIndex, panel) => (event, isExpanded) => {
    setExpandedStates((prev) => ({
      ...prev,
      [subcategoryIndex]: isExpanded ? panel : null, // Update the expanded state for the specific subcategory
    }));
  };

  const renderFAQs = (improvementSuggestions, subcategoryIndex) => {
    return improvementSuggestions.map((faq, index) => (
      <Accordion key={index} expanded={expandedStates[subcategoryIndex] === `panel${index}`} onChange={handleAccordionChange(subcategoryIndex, `panel${index}`)}>
        <AccordionSummary>
          <span className="accordion_icon">{expandedStates[subcategoryIndex] === `panel${index}` ? <RemoveIcon /> : <AddIcon />}</span>
          {faq.question}
        </AccordionSummary>
        <AccordionDetails>
          <span className="accordion_pera">{faq.answer}</span>
        </AccordionDetails>
      </Accordion>
    ));
  };

  return (
<>
<div className="analysis_content" >
        {Object.entries(pdfDetails.atsData).map(([sectionKey, sectionValue], sectionIndex) => {
          if (typeof sectionValue === "object" && sectionValue.percentage) {
            return (
       
             
              
                   
                  <section className="section-padding" key={sectionKey} id={sectionKey} ref={sectionRefs[sectionKey]}>
                    <div className="section_box  ">
                      <div className="section_head">
                        <div className="section_title">
                          <h2>{sectionKey.charAt(0).toUpperCase() + sectionKey.slice(1)}</h2>
                        </div>
                      </div>
                      {sectionValue.subcategories && (
                        <div className="section_body">
                          {sectionValue.subcategories.map((subcategory, subIndex) => (
                            <div className="section_card" id="content" key={subIndex}>
                              <div className="section_card_head">
                                <div className="section_card_title"> <h4>{subcategory.key} </h4></div>
                              </div>
                              <div className="section_card_body">
                                <p>{subcategory.detail.analysis}</p>

                                {subcategory.detail.improvement_suggestion?.length > 0 && (
                                  <div className="inner_card">
                                    <div className="inner_card_head">
                                      <div className="inner_card_title">FAQ</div>
                                    </div>
                                    <div className="inner_card_body">
                                      <div className="faq_accordion">{renderFAQs(subcategory.detail.improvement_suggestion, subIndex)}</div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </section>
               
               
            );
          }
          return null;
        })}
         </div>
 </>
  );
};

export default PdfContent;
