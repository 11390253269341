import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Lottie from "lottie-react";  
import 'react-phone-number-input/style.css'
import { styled } from '@mui/material/styles';
import  {TextField, Select, MenuItem,Button,Modal,Box, Radio, RadioGroup ,FormGroup,Checkbox,FormControlLabel,Switch  } from '@mui/material';
import HeaderPage from '../_layout/elements/header';
import FooterPage from '../_layout/elements/footer';
import fileupload from '../assets/images/fileUpload_resume.png'; 

export const UploadResume = () => {
 
 


const [loading, setLoading] = useState(false);
const [image, setImage] = useState(null);
const fileInputRef = useRef(null);
const handleFileChange = (e) => {
const file = e.target.files[0];
if (file) {
setLoading(true);
const reader = new FileReader();
reader.onloadend = () => {
setLoading(false);
setImage(reader.result);
};
reader.readAsDataURL(file);
}
};
const handleRemoveImage = () => {
setImage(null);
// Reset the input field
if (fileInputRef.current) {
fileInputRef.current.value = '';
}
};

return (
<div>
   <HeaderPage />
   <section className="upload_banner">
      <div className="container" style={{position:'relative'}}>
      <div className='row' >
            <div className='banner-content-box' >

               
               <div className='upload_banner_content' >
                  <h1>ATS Resume Checker and    <span className='text-color' >AI   </span> Scoring Tool </h1>
                  <p>A free and fast AI resume checker doing 16 crucial checks to ensure your resume is ready to perform and get you interview callbacks.</p>
            
               </div>


               <div className='upload_box'>
               <div className='csv-upload  '  >
            {!loading && !image && (
            <div className='chooseimage thumbnil-upload '>
               <label htmlFor="imageInput" >
                  <div className='svg-circle'>
                  <img src={fileupload}/>
                  </div>
                  <h5> Upload your resume to evaluate your score
                  </h5>
                  <p className='text-dark text-center' style={{fontSize:'14px' , marginBottom:'3px'}}>Drag & Drop your Resume here or Browse to choose file
                   <br/>   </p>
                   <span><small className='text-muted'>(pdf, docx - 2MB max file we support)</small></span>
               </label>
            </div>
            )}
            <input
            type="file"
            onChange={handleFileChange}
            style={{ display: 'none' }}
            id="imageInput"
            ref={fileInputRef}
            />
            {loading && 
            <p>Loading Image...</p>
            }
            {image && !loading && (
            <div className='uploaded-image'>
              
               <div className='upload_file_name'>
               <h5> We are evaluating your resume score  </h5>
                  <span> 651e0e326904a-business-intelligence-and-analytics.pdf </span>
             
               </div>
          
            </div>
            )}
         </div>


               </div>


 
             
            </div>
         </div>
</div>
</section>
 
<FooterPage />
</div>
)
}