import React, { useState, useEffect, useRef, use } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Lottie from "lottie-react";
import { Editor as DraftEditor, EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Document, Page, pdfjs } from "react-pdf";
import { TagsInput } from "react-tag-input-component";
import {
  Avatar,
  Box,
  Tooltip,
  LinearProgress,
  Typography,
  SwipeableDrawer,
  TextField,
  FormControl,
  IconButton,
  Modal,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
  Button,
} from "@mui/material";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import SwapHorizOutlinedIcon from "@mui/icons-material/SwapHorizOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import EditIcon from "@mui/icons-material/Edit";
import "swiper/css/pagination";
import "swiper/css";
import jobdescriptionanimation from "../assets/images/description-animation.gif";
import jobdescription from "../assets/images/jondescription.png";
import noimage from "../assets/images/nofoundimage.png";
import savetime from "../assets/images/real-time.gif";
import Screening from "../assets/json/AI-Application-Received.json";
import fake from "../assets/json/fake.json";
import Single from "../assets/json/allplateform.json";
import Eliminate from "../assets/images/AI-passive-candidate-sourcing.gif";
import aiimage from "../assets/images/ai.gif";
import Better from "../assets/json/MIS.json";
import why1 from "../assets/images/about_s4_lines.png";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import HeaderPage from "../_layout/elements/header";
import FooterPage from "../_layout/elements/footer";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { Link, useNavigate } from "react-router-dom";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import WorkOutlinedIcon from "@mui/icons-material/WorkOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import SchoolIcon from "@mui/icons-material/School";
import { useGoogleLogin } from "@react-oauth/google";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import pageloader from "../assets/images/ai.gif";
import PageLoader from "../components/page-loader";
import MessageSnackbar from "../_layout/elements/snackbar";
import host from "../host";
import ContentLoader from "../components/content-loader";
import fileupload from "../assets/images/fileUpload_resume.png";

export const Home = () => {
   const navigate = useNavigate();
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const defaultContent = "job description appear automatically";

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [editorState2, setEditorState2] = useState(EditorState.createEmpty());

  // Handle editor state change
  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
    // You can also update other state variables or perform any necessary logic
  };

  //matrix function    start//
  useEffect(() => {
    const canvas = window.document.querySelector("canvas");
    let letters = "123456789101234567891012345678910123456789101234567891012345678910123456789101234567891012345678910123456789101234567891012345678910";
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    const fontSize = 10;
    const columns = canvas.width / fontSize;
    const ctx = canvas.getContext("2d");
    const drops = [];
    // Setting the width and height of the canvas
    // Setting up the letters
    letters = letters.split("");
    // Setting up the columns
    // Setting up the drops
    for (let i = 0; i < columns; i++) {
      drops[i] = 1;
    }
    // Setting up the draw function
    function draw() {
      ctx.fillStyle = "rgba(255, 255,255, .1)";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      for (let i = 0; i < drops.length; i++) {
        const text = letters[Math.floor(Math.random() * letters.length)];
        ctx.fillStyle = "#b6b6b6";
        ctx.fillText(text, i * fontSize, drops[i] * fontSize);
        drops[i]++;
        if (drops[i] * fontSize > canvas.height && Math.random() > 0.95) {
          drops[i] = 0;
        }
      }
    }
    setInterval(draw, 30);
  }, []);
  //matrix function    end//
  //   //this below function for Eduaction score    start//
  //   const [eduprogress, edusetProgress] = useState(90);
  //   const edupercentage = Math.round((eduprogress / 100) * 100);
  //   //this below function for Work Experience score    start//
  //   const [workprogress, worksetProgress] = useState(80);
  //   const workpercentage = Math.round((workprogress / 100) * 100);
  //   //this below function for Skills score    start//
  //   const [skillprogress, skillsetProgress] = useState(76);
  //   const skillpercentage = Math.round((skillprogress / 100) * 100);
  //   //this below function for Accomplishments score    start//
  //   const [accprogress, accsetProgress] = useState(25);
  //   const accpercentage = Math.round((accprogress / 100) * 100);
  const [isdataSet, setIsDataSet] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setIsOpen(open);
    setDemoJob("");
  };
  //job description   function start//
  const [loading, setLoading] = useState(false);

  //job description   function end//
  //this below function stepform start//
  const [stepform, setActiveTab3] = useState("Insights");
  const handleTabClick3 = (tab) => {
    setActiveTab3(tab);
  };

  //this below function stepform end//
  //this below function for action button start//
  const [activeTabai, setActiveTabai] = useState(" ");
  const handleTabClickai = (tab) => {
    // setActiveTabai(tab);
    setActiveTabai((prevActiveTab) => (prevActiveTab === tab ? null : tab));
  };
  const [numPages, setNumPages] = useState(null);

  //for youtube
  const scrollToAnchor = (anchorId) => {
    const element = document.getElementById(anchorId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  function onDocumentLoadSuccess(pdf) {
    setNumPages(pdf?.numPages);
  }

  const [predefinedJobs, setPredefinedJobs] = useState([]);
  const [predefinedProfiles, setPredefinedProfiles] = useState([]);
  const [job_title, setJobTitle] = useState("");
  const [description, setDescription] = useState("");
  const [message, setMessage] = useState("");
  const [cv, setCv] = useState("");
  const [analysisData, setAnalysisData] = useState([]);
  const [demoJob, setDemoJob] = useState("");
  const [demoProfile, setDemoProfile] = useState("");
  const [signedUrlCV, setSignedUrlCV] = useState("");

  const predefinedDataGet = () => {
    fetch(process.env.REACT_APP_API_URL + "/api/v1/public/demo/predefined_data", {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then(async (data) => {
        if (data.success) {
          if (data.jobs) {
            setPredefinedJobs(data.jobs);
          }
          if (data.profiles) {
            setPredefinedProfiles(data.profiles);
          }

        }
      })
      .catch((err) => {
        //setLoading(false)
        console.log(err);
      });
  };

  const googleLogin = (tokenResponse) => {
    setMessage("");
    setLoading(true);
    setAnalysisData([]);

    fetch(process.env.REACT_APP_SSO_API_URL + "/api/v1/auth/google", {
      method: "POST",
      body: JSON.stringify({ credential: tokenResponse }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then(async (data) => {
        setLoading(false);
        if (data.success) {
          localStorage.setItem("google_token", data.token);
          if (isdataSet && isdataSet === "generateAI") {
            postAnalysis();
          } else if (isdataSet && isdataSet === "login") {
            window.open(process.env.REACT_APP_SELECTRIGHT_URL + "/signin/" + localStorage.getItem("google_token"), "_self");
          } else if (isdataSet && isdataSet === "generateJD") {
            generateJD1();
          }

          // console.log(data);
        } else {
          setMessage("Server under heavy load please try later !");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const postAnalysis = () => {
    if (job_title === "" && demoJob === "") {
      setMessage("Please provide job title or select demo job");
    }
    // else if (description == '' && demoJob == '') {
    //   setMessage('Please provide job description or select demo job')
    // }
    else if (editorState.getCurrentContent().hasText() === false && demoJob === "") {
      setMessage("Please provide job description or select demo job");
    } else if (cv === "" && demoProfile === "") {
      setMessage("Please upload cv or select demo profile.");
    } else {
      setMessage("");
      setLoading(true);

      let formData = new FormData();

      if (cv !== "") {
        formData.append("cv", cv);
        formData.append("is_default_profile", false);
      } else {
        formData.append("demo_profile", demoProfile);
        formData.append("is_default_profile", true);
      }

      if (demoJob !== "") {
        formData.append("is_default_job", true);
        formData.append("demo_job", demoJob);
      } else {
        formData.append("is_default_job", false);
        formData.append("job_title", job_title);
        // formData.append("description", contentText);
        formData.append("description", JSON.stringify(convertToRaw(editorState.getCurrentContent())));
        //formData.append("description", convertToRaw(editorState.getCurrentContent()));
      }

      fetch(process.env.REACT_APP_API_URL + "/api/v1/public/demo/analysis", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("google_token")}`,
        },
        body: formData,
      })
        .then((res) => {
          return res.json();
        })
        .then(async (data) => {
          setLoading(false);
          if (data.success) {
            setAnalysisData(data.analysis);
            setSignedUrlCV(data.signed_url_cv);
            //setJobDescription(data.description)
            if (data.description && Array.isArray(data.description.blocks)) {
              const contentState = convertFromRaw({
                blocks: data.description.blocks,
                entityMap: {}, // Add a dummy entityMap for now
              });

              const editorState2 = EditorState.createWithContent(contentState);

              setEditorState2(editorState2);
            } else {
              console.error("Invalid description data or blocks array is missing");
            }
          } else {
            if (data.message === "Free analysis limit reached. Please complete KYC to use platform") {
              setMessage("Your free analysis limit is exceeded. Please complete KYC to use platform");
            } else {
              setMessage("Server under heavy load please try later !");
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };
  //ai description functionality begins

  const commonpopup = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    width: "40%",
  };
  const [open1, setOpen1] = useState(false);
  const active = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const [openaidescription, setOpen] = React.useState(false);
  const [isLoaded, setIsLoaded] = useState(true);

  const handleClosedescription = () => {
    setOpen(false);
  };
  const handleChipClick = (skill) => {
    if (skills.includes(skill)) {
      setSkills(skills.filter((s) => s !== skill));
    } else {
      setSkills([...skills, skill]);
    }
  };

  const [skills, setSkills] = useState(["programming"]);
  const [suggestedText, setSuggestedText] = useState("");
  const [jobDescriptionJD1, setJobDescriptionJD1] = useState("");
  const [jobDescriptionJD2, setJobDescriptionJD2] = useState("");
  const [severity, setSeverity] = useState("success");
  // const [message, setMessage] = useState('')
  const [snackbar, setSnackbar] = useState(false);
  const [tempEditorState, setTempEditorState] = useState(() => EditorState.createEmpty());
  const onClose = () => {
    setSnackbar(false);
  };
  useEffect(() => {
    if (snackbar) {
      setTimeout(() => {
        setSnackbar(false);
      }, 3000);
    }
  }, [snackbar]);

  const handleTempEditorChange = (newEditorState) => {
    setTempEditorState(newEditorState);
  };

  const descriptionsuggestion = () => {
    if (job_title === "") {
      setSeverity("warning");
      setMessage("Please give job title");
      setSnackbar(true);
      return;
    }
    setTempEditorState(editorState);
    setOpen(true);
  };

  const [job_desc_loading, set_job_desc_loading] = useState(false);
  const [suggestion_loading, setSuggestionLoading] = useState(false);

  const [isEditJobPosition, setIsEditJobPosition] = useState(false);
  const [jobPosition, setJobPosition] = useState("");
  const [isEditSeniority, setIsEditSeniority] = useState(false);
  const [seniority, setSeniority] = useState("");
  const [isEditDepartment, setIsEditDepartment] = useState(false);
  const [jDepartment, setJDepartment] = useState("");
  const [isEditSpeciality, setIsEditSpeciality] = useState(false);
  const [jSpeciality, setJSpeciality] = useState("");
  const [isEditLocation, setIsEditLocation] = useState(false);
  const [jLocation, setJLocation] = useState("");

  const [isEditJBusiness, setIsEditJBusiness] = useState(false);
  const [jBusiness, setJBusiness] = useState("");
  const [isEditJOverview, setIsEditJOverview] = useState(false);
  const [jOverview, setJOverview] = useState("");
  const [isEditJStructure, setIsEditJStructure] = useState(false);
  const [jStructure, setJStructure] = useState("");
  const [isEditJCompanySize, setIsEditJCompanySize] = useState(false);
  const [jCompanySize, setJCompanySize] = useState("");
  const [isEditJAwards, setIsEditJAwards] = useState(false);
  const [jAwards, setJAwards] = useState("");

  const [isEditPrimaryDuties, setIsEditPrimaryDuties] = useState(false);
  const [jPrimaryDuties, setJPrimaryDuties] = useState([]);

  const [isEditJDescisionMaking, setIsEditJDescisionMaking] = useState(false);
  const [jDescisionMaking, setJDescisionMaking] = useState([]);

  const [isEditCollaborativeDuties, setIsEditCollaborativeDuties] = useState(false);
  const [jCollaborativeDuties, setJCollaborativeDuties] = useState([]);

  const [isEditLeadershipDuties, setIsEditLeadershipDuties] = useState(false);
  const [jLeadershipDuties, setJLeadershipDuties] = useState([]);

  const [isEditProjectRoles, setIsEditProjectRoles] = useState(false);
  const [jProjectRoles, setJProjectRoles] = useState([]);

  const [isEditCustomerService, setIsEditCustomerService] = useState(false);
  const [jCustomerService, setJCustomerService] = useState([]);

  const [isEditEductionalQualifications, setIsEditEducationalQualifications] = useState(false);
  const [jEducationalQualifications, setJEducationalQualifications] = useState("");
  const [isEditWorkExperience, setIsEditWorkExperience] = useState(false);
  const [jWorkExperience, setJWorkExperience] = useState("");

  const [isEditKnowledgeSkillsAbilities, setIsEditKnowledgeSkillsAbilities] = useState(false);
  const [jKnowledgeSkillsAbilities, setJKnowledgeSkillsAbilities] = useState([]);

  const [isEditOtherQualifications, setIsEditOtherQualifications] = useState(false);
  const [jOtherQualifications, setJOtherQualifications] = useState([]);

  const [skillsRequired, setSkillsRequired] = useState([]);
  const [isNewDescriptionSet, setIsNewDescriptionSet] = useState(false);

  const [jobCount, setjobCount] = useState(0);
  const [companyCount, setCompanyCount] = useState(0);
  const [rolesCount, setRolesCount] = useState(0);
  const [qualificationsCount, setQualificationsCount] = useState(0);

  useEffect(() => {
    const nonEmptyValues = [jobPosition, seniority, jDepartment, jSpeciality, jLocation].filter((value) => Boolean(value.trim()));
    const newJobCount = nonEmptyValues.length;
    setjobCount(newJobCount);
  }, [jobPosition, seniority, jDepartment, jSpeciality, jLocation]);

  useEffect(() => {
    const nonEmptyValues = [jOverview, jBusiness, jStructure, jCompanySize, jAwards].filter((value) => Boolean(value.trim()));
    const newCompanyCount = nonEmptyValues.length;
    setCompanyCount(newCompanyCount);
  }, [jOverview, jBusiness, jStructure, jCompanySize, jAwards]);

  useEffect(() => {
    const arrays = [jPrimaryDuties, jDescisionMaking, jProjectRoles, jCollaborativeDuties, jLeadershipDuties, jCustomerService];
    const newRolesCount = arrays.reduce((count, arr) => (arr.length > 0 ? count + 1 : count), 0);
    setRolesCount(newRolesCount);
  }, [jPrimaryDuties, jDescisionMaking, jProjectRoles, jCollaborativeDuties, jLeadershipDuties, jCustomerService]);

  useEffect(() => {
    const stringVariables = [jEducationalQualifications, jWorkExperience];
    const arrayVariables = [jKnowledgeSkillsAbilities, jOtherQualifications];

    const nonEmptyStringsCount = stringVariables.filter((str) => str.trim() !== "").length;

    const nonEmptyArraysCount = arrayVariables.filter((arr) => arr.length > 0).length;

    const newQualificationsCount = nonEmptyStringsCount + nonEmptyArraysCount;

    setQualificationsCount(newQualificationsCount);
  }, [jEducationalQualifications, jWorkExperience, jKnowledgeSkillsAbilities, jOtherQualifications]);

  const handleRefreshJDAnalysis = () => {
    getSuggestions();
  };

  const handleJDsubmit = () => {
    setEditorState(tempEditorState);
    handleClose1();
    handleClosedescription();
  };

  const getSuggestions = () => {
    if (!tempEditorState.getCurrentContent().hasText()) {
      setSeverity("error");
      setMessage("Provide a rough description first");
      setSnackbar(true);
      return;
    }
    if (job_desc_loading) {
      setSeverity("error");
      setMessage("Let the description be loaded first!");
      setSnackbar(true);
      return;
    }
    setSuggestionLoading(true);
    fetch(host.hostname + `/api/v1/ai/jd/details`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("google_token")}`,
      },
      body: JSON.stringify({
        job_title: job_title,
        job_description: convertToRaw(tempEditorState.getCurrentContent()),
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.success) {
          setSuggestionLoading(false);

          console.log(data);

          setIsNewDescriptionSet(false);
          setJobDescriptionJD2(data.job_details);
          if (data.job_details && data.job_details.job_title) {
            setJobPosition(data.job_details.job_title.job_position || "");
            setSeniority(data.job_details.job_title.level_of_seniority || "");
            setJDepartment(data.job_details.job_title.department || "");
            setJSpeciality(data.job_details.job_title.speciality || "");
            setJLocation(data.job_details.job_title.Location || "");
          }
          if (data.job_details && data.job_details.company) {
            setJOverview(data.job_details.company.Overview || "");
            setJBusiness(data.job_details.company.Scope_of_business || "");
            setJStructure(data.job_details.company.Organization_structure || "");
            setJCompanySize(data.job_details.company.Company_Size || "");
            setJAwards(data.job_details.company.Recognition_and_awards || "");
          }

          if (data.job_details && data.job_details.roles_and_responsibilities) {
            setJDescisionMaking(data.job_details.roles_and_responsibilities.descision_making_responsibilities || []);
            setJPrimaryDuties(data.job_details.roles_and_responsibilities.primary_duties || []);
            setJCollaborativeDuties(data.job_details.roles_and_responsibilities.collaborative_duties || []);
            setJProjectRoles(data.job_details.roles_and_responsibilities.project_roles || []);
            setJLeadershipDuties(data.job_details.roles_and_responsibilities.leadership_duties || []);
            setJCustomerService(data.job_details.roles_and_responsibilities.customer_service || []);
          }

          if (data.job_details && data.job_details.preferred_qualifications) {
            setJEducationalQualifications(data.job_details.preferred_qualifications.education_qualification || "");
            setJWorkExperience(data.job_details.preferred_qualifications.work_experience || "");
            setJKnowledgeSkillsAbilities(data.job_details.preferred_qualifications.knowledge_skills_abilities || []);
            setJOtherQualifications(data.job_details.preferred_qualifications.other_qualifications || []);
          }

          if (data.job_details && data.job_details.skills_required) {
            if (skills.length === 0) {
              setSkills(data.job_details.skills_required);
            } else {
              setSkillsRequired(data.job_details.skills_required);
            }
          }
        } else {
          setSeverity("error");
          setMessage("Server under heavy load please try later !");
          setSnackbar(true);
          setSuggestionLoading(false);
        }
      })
      .catch((err) => {
        setSuggestionLoading(false);
        setSeverity("error");
        setMessage("Server under heavy load please try later !");
        setSnackbar(true);
        console.log(err);
      });
  };

  useEffect(() => {
    const contentState = tempEditorState.getCurrentContent();
    const hasText = contentState.hasText();
    if (hasText && isNewDescriptionSet) {
      getSuggestions();
    }
  }, [tempEditorState]);

  const generateJD1 = () => {
    if (job_title === "") {
      setSeverity("warning");
      setMessage("Please give job title");
      setSnackbar(true);
    } else {
      set_job_desc_loading(true);
      fetch(host.hostname + `/api/v1/ai/jd/description`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("google_token")}`,
        },
        body: JSON.stringify({
          job_title: job_title,
          suggested_text: suggestedText,
          job_description: convertToRaw(tempEditorState.getCurrentContent()),
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.success) {
            set_job_desc_loading(false);
            setJobDescriptionJD1(data.job_description);
            let contentState = convertFromRaw(data.job_description);
            let editorStateData = EditorState.createWithContent(contentState);
            setIsNewDescriptionSet(true);
            setTempEditorState(editorStateData);
            setJobDescriptionJD2({});
          } else {
            setSeverity("error");
            setMessage("Server under heavy load please try later !");
            setSnackbar(true);
            set_job_desc_loading(false);
          }
        })
        .catch((err) => {
          set_job_desc_loading(false);
          setSeverity("error");
          setMessage("Server under heavy load please try later !");
          setSnackbar(true);
          console.log(err);
        });
    }
  };

  const generateJD2 = () => {
    let tem = jobDescriptionJD2;
    let data = jobDescriptionJD2;

    if (data.job_title && data.job_title.job_position) {
      tem.job_title.job_position = jobPosition;
    }
    if (data.job_title && data.job_title.level_of_seniority) {
      tem.job_title.level_of_seniority = seniority;
    }
    if (data.job_title && data.job_title.department) {
      tem.job_title.department = jDepartment;
    }
    if (data.job_title && data.job_title.speciality) {
      tem.job_title.speciality = jSpeciality;
    }
    if (data.job_title && data.job_title.Location) {
      tem.job_title.Location = jLocation;
    }

    if (data.company && data.company.Overview) {
      tem.company.Overview = jOverview;
    }
    if (data.company && data.company.Scope_of_business) {
      tem.company.Scope_of_business = jBusiness;
    }
    if (data.company && data.company.Organization_structure) {
      tem.company.Organization_structure = jStructure;
    }
    if (data.company && data.company.Company_Size) {
      tem.company.Company_Size = jCompanySize;
    }
    if (data.company && data.company.Recognition_and_awards) {
      tem.company.Recognition_and_awards = jAwards;
    }

    if (data.roles_and_responsibilities && data.roles_and_responsibilities.primary_duties) {
      tem.roles_and_responsibilities.primary_duties = jPrimaryDuties;
    }

    if (data.roles_and_responsibilities && data.roles_and_responsibilities.descision_making_responsibilities) {
      tem.roles_and_responsibilities.descision_making_responsibilities = jDescisionMaking;
    }

    if (data.roles_and_responsibilities && data.roles_and_responsibilities.collaborative_duties) {
      tem.roles_and_responsibilities.collaborative_duties = jCollaborativeDuties;
    }

    if (data.roles_and_responsibilities && data.roles_and_responsibilities.project_roles) {
      tem.roles_and_responsibilities.project_roles = jProjectRoles;
    }

    if (data.roles_and_responsibilities && data.roles_and_responsibilities.leadership_duties) {
      tem.roles_and_responsibilities.leadership_duties = jLeadershipDuties;
    }

    if (data.roles_and_responsibilities && data.roles_and_responsibilities.customer_service) {
      tem.roles_and_responsibilities.customer_service = jCustomerService;
    }

    if (data.preferred_qualifications && data.preferred_qualifications.education_qualification) {
      tem.preferred_qualifications.education_qualification = jEducationalQualifications;
    }

    if (data.preferred_qualifications && data.preferred_qualifications.work_experience) {
      tem.preferred_qualifications.work_experience = jWorkExperience;
    }

    if (data.preferred_qualifications && data.preferred_qualifications.knowledge_skills_abilities) {
      tem.preferred_qualifications.knowledge_skills_abilities = jKnowledgeSkillsAbilities;
    }

    if (data.preferred_qualifications && data.preferred_qualifications.other_qualifications) {
      tem.preferred_qualifications.other_qualifications = jOtherQualifications;
    }

    set_job_desc_loading(true);
    console.log(tem);

    fetch(host.hostname + `/api/v1/ai/jd/refine`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("google_token")}`,
      },
      body: JSON.stringify({
        job_title: job_title,
        job_details: JSON.stringify(tem),
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.success) {
          setJobDescriptionJD1(data.job_description);
          let contentState = convertFromRaw(data.job_description);
          let editorStateData = EditorState.createWithContent(contentState);
          setTempEditorState(editorStateData);
          set_job_desc_loading(false);
        } else {
          setSeverity("error");
          setMessage("Server under heavy load please try later !");
          setSnackbar(true);
          set_job_desc_loading(false);
        }
      })
      .catch((err) => {
        set_job_desc_loading(false);
        setSeverity("error");
        setMessage("Server under heavy load please try later !");
        setSnackbar(true);
        console.log(err);
      });
  };

  const primaryDutiesAddMore = () => {
    setJPrimaryDuties((current) => [...current, ""]);
  };

  const primaryDutiesRemove = (idx) => {
    const temp = [...jPrimaryDuties];
    temp.splice(idx, 1);
    setJPrimaryDuties(temp);
  };

  const descisionMakingAddMore = () => {
    setJDescisionMaking((current) => [...current, ""]);
  };

  const descisionMakingRemove = (idx) => {
    const temp = [...jDescisionMaking];
    temp.splice(idx, 1);
    setJDescisionMaking(temp);
  };

  const collaborativeDutiesAddMore = () => {
    setJCollaborativeDuties((current) => [...current, ""]);
  };

  const collaborativeDutiesRemove = (idx) => {
    const temp = [...jCollaborativeDuties];
    temp.splice(idx, 1);
    setJCollaborativeDuties(temp);
  };

  const leadershipDutiesAddMore = () => {
    setJLeadershipDuties((current) => [...current, ""]);
  };

  const leadershipDutiesRemove = (idx) => {
    const temp = [...jLeadershipDuties];
    temp.splice(idx, 1);
    setJLeadershipDuties(temp);
  };

  const projectRolesAddMore = () => {
    setJProjectRoles((current) => [...current, ""]);
  };

  const projectRolesRemove = (idx) => {
    const temp = [...jProjectRoles];
    temp.splice(idx, 1);
    setJProjectRoles(temp);
  };

  const customerServiceAddMore = () => {
    setJCustomerService((current) => [...current, ""]);
  };

  const customerServiceRemove = (idx) => {
    const temp = [...jCustomerService];
    temp.splice(idx, 1);
    setJCustomerService(temp);
  };

  const knowledgeSkillsAbilitiesAddMore = () => {
    setJKnowledgeSkillsAbilities((current) => [...current, ""]);
  };

  const knowledgeSkillsAbilitiesRemove = (idx) => {
    const temp = [...jKnowledgeSkillsAbilities];
    temp.splice(idx, 1);
    setJKnowledgeSkillsAbilities(temp);
  };

  const otherQualificationsAddMore = () => {
    setJOtherQualifications((current) => [...current, ""]);
  };

  const otherQualificationsRemove = (idx) => {
    const temp = [...jOtherQualifications];
    temp.splice(idx, 1);
    setJOtherQualifications(temp);
  };

  //ai description functionality ends
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      console.log(tokenResponse);
      googleLogin(tokenResponse);
    },
  });

  useEffect(() => {
    setTimeout(() => {
      document.title = "Selectright.ai - Home";
      predefinedDataGet();
      setIsLoaded(false);
    }, 2000);
  }, []);

  // useEffect(() => {
  //   postAnalysis()

  // }, []);
  const [pdf, setPdf] = useState(null);
  const MAX_FILE_SIZE = 32 * 1024 * 1024; // size limit for file in future
  const fileInputRef = useRef(null);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType !== "application/pdf") {
        return;
      }
      setLoading(true);
      setPdf(file);
      setLoading(false);
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
  };

  const handleFileDrop = (e) => {
    const file = e.dataTransfer.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType !== "application/pdf") {
        return;
      }
      setLoading(true);
      setPdf(file);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (pdf) {
      resumeATSAnalysis();
    }
  }, [pdf]);

  const resumeATSAnalysis = () => {
    let formData = new FormData();

    formData.append("cv", pdf);

    fetch(process.env.REACT_APP_API_URL + "/api/v1/public/demo/ats_analysis", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("google_token")}`,
      },
      body: formData,
    })
      .then((res) => {
        return res.json();
      })
      .then(async (data) => {
        if (data.success) {
         navigate(`pdf-verification/${data.cvId}`)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <HeaderPage />
      <MessageSnackbar open={snackbar} autoHideDuration={3000} onClose={onClose} severity={severity} message={message} />

      <section className="home-wrapper-area bg1 ">
        <canvas className="matrixeffect"></canvas>
        <Swiper pagination={true} className="banner mySwiper">
          <SwiperSlide className="bgheight  ">
            <div className="container">
              <div className="row  align-items-center">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="banner-content " data-aos="fade-up" data-aos-duration="500">
                    <h1>
                      {" "}
                      Hire the right candidate every time with ​<br />
                      <span className="text-blue"> SelectRight.AI </span>
                    </h1>
                    <p> Recruiters use SelectRight.ai to post jobs, test and identify best candidates - faster, smarter, cost-efficient at scale </p>
                    <div className="bookdemobtn ">
                      <button className="btn  btn-blue" type="button" onClick={() => (window.location.href = process.env.REACT_APP_SSO_URL + "/sign-up")}>
                        Start Hiring - It's free
                      </button>
                      <button className="btn  btn-white" type="button" onClick={() => (window.location.href = "/book-demo")}>
                        {" "}
                        Book A Call
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>
      <section className="section-pedding  ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="section-pedding  Transforming">
              <div className="row justify-content-center">
                <div className="col-xl-7 col-lg-7 col-md-6 col-sm-6 col-12">
                  <div className="section-title text-center" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200px">
                    <h2 className>
                      ATS Resume Checker and <span className="text-color">AI </span> Scoring Tool
                    </h2>
                    <p> A free and fast AI resume checker doing 16 crucial checks to ensure your resume is ready to perform and get you interview callbacks.</p>
                  </div>
                </div>
              </div>
              <div className="upload_box" style={{ margin: "0 auto" }}>
                <div className="csv-upload  ">
                  {pdf ? (
                    <div className="uploaded-image">
                      <div className="upload_file_name">
                        <Box sx={{ width: "100%" }}>
                          <div className="CircularProgress_loader text-center">
                            <CircularProgress size="3rem" style={{ margin: "0 auto", marginBottom: "25px" }} className="text-dark" />
                          </div>
                        </Box>
                        <h5> We are evaluating your Resume score </h5>
                        <span>{pdf?.name ? pdf.name : ""}</span>
                      </div>
                    </div>
                  ) : (
                    <div className="chooseimage thumbnil-upload " onDrop={handleFileDrop} onDragOver={handleDrag} onDragEnter={handleDrag} onDragLeave={handleDrag}>
                      <label htmlFor="imageInput">
                        <input type="file" onChange={handleFileChange} style={{ display: "none" }} id="imageInput" ref={fileInputRef} accept="application/pdf" />
                        <div className="svg-circle">
                          <img src={fileupload} alt="Upload" />
                        </div>
                        <h5> Upload your resume to evaluate your score</h5>
                        <p className="text-dark text-center" style={{ fontSize: "14px", marginBottom: "3px" }}>
                          Drag & Drop your Resume here or Browse to choose file
                          <br />{" "}
                        </p>
                        <span>
                          <small className="text-muted">(pdf, docx - 2MB max file we support)</small>
                        </span>
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-pedding howwork d-none">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
              <div className="section-title" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200px">
                <h2 className>How it works </h2>
                <p>SelectRight.ai allows you to manage all your job posts, applications received, assessments and from your dashboard itself</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-8 col-12 mt-15">
              <div className="demovideo " data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200px">
                <video height="560" style={{ width: "100%" }} controls>
                  <source src="path/to/video.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-pedding CVAnalysis">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-7 col-md-6 col-sm-6 col-12">
              <div className="section-title" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200px">
                <h2 className>AI powered Smart CV Analysis</h2>
                <p>
                  {" "}
                  SelectRight.ai automatically generates CV scores and smart insights about candidates using advanced algorithms.{" "}
                  <Link to="#" onClick={() => scrollToAnchor("aianalysistag")} className="text-blue">
                    <b>Try yourself below</b>
                  </Link>
                </p>
                {/* 
                  <div className="try-it">
                     <h5>Try yourself below</h5>
                  </div>
                  */}
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className=" pb-5">
                <iframe
                  width="100%"
                  height="500"
                  style={{ margin: "0 auto" }}
                  src="https://www.youtube.com/embed/DLeHrLq3jFg"
                  title="YouTube video player"
                  frameborder="0"
                  allow="clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
          <div className="cv-analysis-demo" id="aianalysistag">
            <div className="row ">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="webjd" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200px">
                  <div className="webjd-title">
                    <h4>Select / Create Job Description </h4>
                  </div>
                  <div className="detail-cv">
                    {isLoaded ? (
                      <ContentLoader />
                    ) : (
                      predefinedJobs.map((job) => {
                        return (
                          <button
                            type="button"
                            className={demoJob === job._id ? "btn btn-success" : "btn btn-white "}
                            onClick={(e) => {
                              setDemoJob(job._id);
                              setJobTitle("");
                              setDescription("");
                            }}
                          >
                            {job.job_title}
                          </button>
                        );
                      })
                    )}
                    {!isLoaded && (
                      <div className="analysis-upload">
                        <button type="button" className="btn btn-blue" onClick={toggleDrawer(true)}>
                          Create Your Job Description
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="webjd" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200px">
                  <div className="webjd-title">
                    <h4>Select / Upload Candidate CV </h4>
                  </div>
                  <div className="detail-cv">
                    {isLoaded ? (
                      <ContentLoader />
                    ) : (
                      predefinedProfiles.map((profile) => {
                        return (
                          <button
                            type="button"
                            className={demoProfile === profile._id ? "btn btn-success" : "btn btn-white "}
                            onClick={(e) => {
                              setDemoProfile(profile._id);
                              setCv("");
                            }}
                          >
                            {profile.profile_name}
                          </button>
                        );
                      })
                    )}

                    {!isLoaded && (
                      <div className="analysis-upload">
                        <label htmlFor="cover-photo">
                          <Link to=" " className="btn btn-blue ">
                            <FileUploadOutlinedIcon />
                            Upload CV{" "}
                          </Link>
                          <input
                            onChange={(e) => {
                              setCv(e.target.files[0]);
                              setDemoProfile("");
                            }}
                            type="file"
                            id="cover-photo"
                            accept="application/pdf"
                          />
                        </label>
                      </div>
                    )}
                  </div>
                </div>
                <div className="select-aibtn text-center" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200px">
                  {cv !== "" || job_title !== "" ? (
                    <>
                      {localStorage.getItem("google_token") ? (
                        <button disabled={loading} onClick={postAnalysis} type="button" className="btn btn-primary">
                          <SmartToyOutlinedIcon />
                          {loading ? "Loading..." : "Generate AI Insights"}
                        </button>
                      ) : (
                        <button
                          disabled={loading}
                          onClick={() => {
                            setIsDataSet("generateAI");
                            login();
                          }}
                          type="button"
                          className="btn btn-primary"
                        >
                          <SmartToyOutlinedIcon />

                          {loading ? "Loading..." : "Generate AI Insights"}
                        </button>
                      )}
                    </>
                  ) : (
                    <button disabled={loading} onClick={postAnalysis} type="button" className="btn btn-primary">
                      <SmartToyOutlinedIcon />
                      {loading ? "Loading..." : "Generate AI Insights"}
                    </button>
                  )}
                </div>

                <div style={{ display: "flex", width: 100 + "%", justifyContent: "center", alignItems: "center", color: "red", margin: 10 }}>{message}</div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                {!loading ? (
                  <>
                    {analysisData.basic_info ? (
                      <div className="ai-details">
                        <div className="ai-tabs  applicant-status-tab">
                          <ul className="nav nav-tabs  ">
                            <li className="nav-item">
                              <Link to="#" className={`tab nav-link ${stepform === "jd" ? "active" : " "}`} onClick={() => handleTabClick3("jd")}>
                                Job Description
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link to="#" className={`tab nav-link ${stepform === "CV" ? "active" : " "}`} onClick={() => handleTabClick3("CV")}>
                                Candidate CV
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link to="#" className={`tab nav-link ${stepform === "Insights" ? "active" : ""}`} onClick={() => handleTabClick3("Insights")}>
                                Candidate Insights
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="ai-result ">
                          {stepform === "Insights" && (
                            <div className="card cv-analysis-detail">
                              <div className="result-action aicv-height">
                                {analysisData.basic_info && (
                                  <div className="candidate-summary-top">
                                    <div className="user-profile-pick">
                                      <Avatar>{analysisData.basic_info.full_name.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), "")}</Avatar>
                                    </div>
                                    <div className="candiatend">
                                      <h5 className=" mb-1"> {analysisData.basic_info.full_name}</h5>
                                      <span> {analysisData.basic_info.email}</span>
                                    </div>
                                    <div className="cvversion" style={{ marginLeft: "auto" }}>
                                      {" "}
                                      v{process.env.REACT_APP_CV_VERSION}
                                    </div>
                                  </div>
                                )}

                                <div className="candidatescore-head ">
                                  <DonutSmallIcon />
                                  <h3 className="mb-0">Analysis </h3>
                                </div>
                                <div className="candidate-summary-right">
                                  <div className="candidate-score-card  ">
                                    {analysisData.analysis && analysisData.analysis.education_and_qualifications && (
                                      <div className="candidatescore-row">
                                        <div class="score-name">
                                          <h5> Education and Qualifications </h5>
                                        </div>
                                        <div class="score-percentage">
                                          <LinearProgress variant="determinate" color="success" value={analysisData.analysis.education_and_qualifications.score} className="progressBar percentbar" />
                                          <Typography variant="body2" color="text.secondary" className="percentnumber">
                                            {analysisData.analysis.education_and_qualifications.score}%
                                          </Typography>
                                        </div>
                                      </div>
                                    )}
                                    {analysisData.analysis && analysisData.analysis.work_experience && (
                                      <div className="candidatescore-row">
                                        <div class="score-name">
                                          <h5> Work Experience </h5>
                                        </div>
                                        <div class="score-percentage">
                                          <LinearProgress variant="determinate" color="primary" value={analysisData.analysis.work_experience.score} className="percentbar" />
                                          <Typography variant="body2" color="text.secondary" className="percentnumber">
                                            {analysisData.analysis.work_experience.score}%
                                          </Typography>
                                        </div>
                                      </div>
                                    )}

                                    {analysisData.analysis && analysisData.analysis.skills_and_abilities && (
                                      <div className="candidatescore-row">
                                        <div class="score-name">
                                          <h5> Skills & Abilities </h5>
                                        </div>
                                        <div class="score-percentage">
                                          <LinearProgress variant="determinate" color="warning" value={analysisData.analysis.skills_and_abilities.score} className="percentbar" />
                                          <Typography variant="body2" color="text.secondary" className="percentnumber">
                                            {analysisData.analysis.skills_and_abilities.score}%
                                          </Typography>
                                        </div>
                                      </div>
                                    )}

                                    {analysisData.analysis && analysisData.analysis.accomplishments_and_achievements && (
                                      <div className="candidatescore-row">
                                        <div class="score-name">
                                          <h5> Accomplishments & Achievements </h5>
                                        </div>
                                        <div class="score-percentage">
                                          <LinearProgress variant="determinate" color="error" value={analysisData.analysis.accomplishments_and_achievements.score} className="percentbar" />
                                          <Typography variant="body2" color="text.secondary" className="percentnumber">
                                            {analysisData.analysis.accomplishments_and_achievements.score}%
                                          </Typography>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="Analysis-wrapper">
                                  {/* {analysisData && analysisData.skills && (
                                    <div className="analysis-box">
                                      <div className={`tab flex-head ${activeTabai === "Skills" ? "active" : ""
                                        }`} onClick={() => handleTabClickai("Skills")}>
                                        <div class="card-icon  ">
                                          <SwitchAccessShortcutAddOutlinedIcon />
                                        </div>
                                        <h5>Skills</h5>
                                        <div className="downarrow ml-auto" style={{ marginLeft: 'auto' }}>
                                          <KeyboardArrowDownOutlinedIcon />
                                        </div>
                                      </div>
                                      {activeTabai === "Skills" && (
                                        <div className="summary-group-wrapper">
                                          <div className="skill-indicator"  >
                                            <ul>
                                              <li>
                                                <span class="statusdot bg-success"></span>
                                                <span>Required For This Job </span>
                                              </li>
                                              <li>
                                                <span class="statusdot bg-danger"></span>
                                                <span>Required But Not Available</span>
                                              </li>
                                              <li>
                                                <span class="statusdot bg-primary"></span>
                                                <span>Additional Skills</span>
                                              </li>
                                            </ul>
                                          </div>
                                          <div className="diffrent-skill">
                                            <div className="form-skill">
                                              <Stack className=" Collaboratorname mt-2" direction="row" style={{ maxHeight: 'inherit', overflow: 'inherit' }}  >
                                                {jobSkills &&
                                                  jobSkills.length > 0 &&
                                                  jobSkills.map(
                                                    (skill_t) => {
                                                      let skill = skill_t.toUpperCase()
                                                      let success = analysisData.skills.includes(skill);

                                                      return (
                                                        <Chip icon={
                                                          <CheckOutlinedIcon />
                                                        } label={skill} color={success ? 'success' : 'error'} />
                                                      );
                                                    }
                                                  )}
                                              </Stack>
                                            </div>
                                            <div className="form-skill additionalskill">
                                              <Stack className=" Collaboratorname mt-2" direction="row" style={{ maxHeight: 'inherit', overflow: 'inherit' }}  >

                                                {analysisData.skills &&
                                                  analysisData.skills.length > 0 &&
                                                  analysisData.skills.map(
                                                    (skill_t) => {
                                                      let skill = skill_t.toUpperCase()
                                                      let exists = jobSkills.includes(skill);
                                                      if (!exists) {
                                                        return (
                                                          <Chip icon={
                                                            <SwitchAccessShortcutAddOutlinedIcon />
                                                          } label={skill} color="primary" />
                                                        );
                                                      } else {
                                                        return ('');
                                                      }
                                                    }
                                                  )}
                                              </Stack>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )} */}

                                  {analysisData.work_experience && (
                                    <div className="analysis-box">
                                      <div className={`tab flex-head ${activeTabai === "Work" ? "active" : ""}`} onClick={() => handleTabClickai("Work")}>
                                        <div class="card-icon  ">
                                          <WorkOutlinedIcon />
                                        </div>
                                        <h5>Work Experience</h5>
                                        <div className="downarrow ml-auto" style={{ marginLeft: "auto" }}>
                                          <KeyboardArrowDownOutlinedIcon />
                                        </div>
                                      </div>
                                      {activeTabai === "Work" && (
                                        <div className="summary-group-wrapper">
                                          <div className="candidatetest-row cv-collapes ">
                                            {analysisData.work_experience.length > 0 ? (
                                              analysisData.work_experience.map((workex, index) => {
                                                return (
                                                  <>
                                                    <Accordion>
                                                      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                                        <Typography>
                                                          <div className="candidate-work-head">
                                                            <div className="srnum  ">{index + 1}</div>
                                                            <div className="work-head-content">
                                                              {workex.company && (
                                                                <div className="company-name">
                                                                  <span>
                                                                    <b>{workex.company} </b>
                                                                  </span>
                                                                </div>
                                                              )}

                                                              <div className="workdate">
                                                                {workex.job_title && (
                                                                  <div className="date-cta">
                                                                    <i className="las la-user-graduate"></i>
                                                                    <span>
                                                                      <b>{workex.job_title}</b>
                                                                    </span>
                                                                  </div>
                                                                )}
                                                                {workex.location && (
                                                                  <div className="date-cta">
                                                                    <i className="las la-map-marker"></i>
                                                                    <span>
                                                                      <b>{workex.location}</b>
                                                                    </span>
                                                                  </div>
                                                                )}

                                                                {workex.job_type && (
                                                                  <div className="date-cta">
                                                                    <i className="las la-business-time"></i>
                                                                    <span>
                                                                      <b>{workex.job_type}</b>
                                                                    </span>
                                                                  </div>
                                                                )}
                                                                {workex.job_start_year && (
                                                                  <div className="date-cta">
                                                                    <span className="text-muted">From </span>
                                                                    <span>-</span>
                                                                    <span>
                                                                      <b>{workex.job_start_month ? workex.job_start_month + "/" + workex.job_start_year : workex.job_start_year}</b>
                                                                    </span>
                                                                  </div>
                                                                )}
                                                                {(workex.job_end_year || workex.job_end_month) && (
                                                                  <div className="date-cta">
                                                                    <span className="text-muted">To </span>

                                                                    <span>-</span>

                                                                    <span>
                                                                      <b>
                                                                        {workex.job_end_month
                                                                          ? workex.job_end_month === "Present"
                                                                            ? "Present"
                                                                            : workex.job_end_month + "/" + workex.job_end_year
                                                                          : workex.job_end_year}
                                                                      </b>
                                                                    </span>
                                                                  </div>
                                                                )}
                                                                {workex.duration && (
                                                                  <div className="date-cta">
                                                                    <span className="text-muted">Duration </span>
                                                                    <span>-</span>
                                                                    {/* <span><b>{(workex.duration / 12).toFixed(2)} years</b></span> */}
                                                                    <span>
                                                                      <b>{workex.duration}</b>
                                                                    </span>
                                                                  </div>
                                                                )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </Typography>
                                                      </AccordionSummary>
                                                      <AccordionDetails>
                                                        <Typography>
                                                          <div className="cv-card-wrapper">
                                                            <div className="card-row">
                                                              <div className="card-content">
                                                                <div className="company">
                                                                  <span>
                                                                    {" "}
                                                                    <b>Job Summary</b>{" "}
                                                                  </span>
                                                                  <span>{workex.brief_job_summary}</span>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </Typography>
                                                      </AccordionDetails>
                                                    </Accordion>

                                                    {workex.work_gap !== undefined ? (
                                                      <div className="middle-gap">
                                                        <div className="middle-row">
                                                          <span className="middle-lable  ">
                                                            {" "}
                                                            <SwapVertIcon /> Gap{" "}
                                                          </span>
                                                          <spam>-</spam>
                                                          <span className="middle-value">
                                                            <b>{workex.work_gap < 12 ? workex.work_gap.toString() + " Months" : (workex.work_gap / 12).toFixed(2).toString() + " years"}</b>
                                                          </span>
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                );
                                              })
                                            ) : (
                                              <div className="no-experience">
                                                <Typography variant="body2" color="text.secondary">
                                                  <center>Work Insights not shared in CV</center>
                                                </Typography>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )}

                                  {analysisData.education && (
                                    <div className="analysis-box">
                                      <div className={`tab flex-head ${activeTabai === "Education" ? "active" : ""}`} onClick={() => handleTabClickai("Education")}>
                                        <div class="card-icon  ">
                                          <SchoolIcon />
                                        </div>
                                        <h5>Education </h5>
                                        <div className="downarrow ml-auto" style={{ marginLeft: "auto" }}>
                                          <KeyboardArrowDownOutlinedIcon />
                                        </div>
                                      </div>
                                      {activeTabai === "Education" && (
                                        <div className="summary-group-wrapper">
                                          <div className="candidatetest-row cv-collapes ">
                                            {analysisData && analysisData.education && analysisData.education.length > 0 && analysisData.education instanceof Array ? (
                                              analysisData.education.map((edu, index) => {
                                                // let start_month_date = new Date();
                                                // start_month_date.setMonth(edu.start_month - 1);
                                                // let start_month = start_month_date.toLocaleString([], { month: 'long' });

                                                // let end_month_date = new Date();
                                                // end_month_date.setMonth(edu.end_month - 1);
                                                // let end_month = end_month_date.toLocaleString([], { month: 'long' });
                                                let start_month = "";
                                                let end_month = "";
                                                if (edu.start_month && edu.start_month.length > 2) {
                                                  start_month = edu.start_month;
                                                } else {
                                                  let start_month_date = new Date();
                                                  start_month_date.setMonth(edu.start_month - 1);
                                                  start_month = start_month_date.toLocaleString([], { month: "long" });
                                                }
                                                if (edu.end_month && edu.end_month.length > 2) {
                                                  end_month = edu.end_month;
                                                } else {
                                                  let end_month_date = new Date();
                                                  end_month_date.setMonth(edu.end_month - 1);
                                                  end_month = end_month_date.toLocaleString([], { month: "long" });
                                                }
                                                return (
                                                  <Typography className="educationai">
                                                    <div className="candidate-work-head">
                                                      <div className="srnum  ">{index + 1}</div>
                                                      <div className="work-head-content">
                                                        {edu.university && (
                                                          <div className="company-name">
                                                            <span>
                                                              <b>{edu.university} </b>
                                                            </span>
                                                          </div>
                                                        )}

                                                        <div className="workdate">
                                                          {edu.degree && (
                                                            <div className="date-cta">
                                                              <i class="las la-user-graduate"></i>
                                                              <span>
                                                                <b>{edu.degree}</b>
                                                              </span>
                                                            </div>
                                                          )}
                                                          {edu.location && (
                                                            <div className="date-cta">
                                                              <i class="las la-map-marker"></i>
                                                              <span>
                                                                <b>{edu.location}</b>
                                                              </span>
                                                            </div>
                                                          )}
                                                          {edu.education_stage && (
                                                            <div className="date-cta">
                                                              <span className="text-muted">Type </span>
                                                              <span>-</span>
                                                              <span>
                                                                <b>{edu.education_stage}</b>
                                                              </span>
                                                            </div>
                                                          )}
                                                          {/* <div className="date-cta">
                                                              <span className="text-muted">From   </span>
                                                              <span>-</span>
                                                              <span><b>July 2016</b></span>
                                                            </div> */}
                                                          {edu.start_year && (
                                                            <div className="date-cta">
                                                              <span className="text-muted">From </span>
                                                              <span>-</span>
                                                              <span>
                                                                <b>
                                                                  {start_month} {edu.start_year}
                                                                </b>
                                                              </span>
                                                            </div>
                                                          )}
                                                          {edu.end_year && (
                                                            <div className="date-cta">
                                                              <span className="text-muted">To </span>
                                                              <span>-</span>
                                                              <span className=" ">
                                                                <b>
                                                                  {end_month} {edu.end_year}
                                                                </b>
                                                              </span>
                                                            </div>
                                                          )}
                                                          {edu.duration && (
                                                            <div className="date-cta">
                                                              <span className="text-muted">Duration </span>
                                                              <span>-</span>
                                                              <span className=" ">
                                                                <b>{(edu.duration / 12).toFixed(2)} Years</b>
                                                              </span>
                                                            </div>
                                                          )}
                                                        </div>
                                                      </div>
                                                      {edu.score && (
                                                        <div className="scoreeducation bg-inverse-success">
                                                          <span className=" score-lable">Score </span>
                                                          <span className="  ">
                                                            <b>{edu.score}%</b>
                                                          </span>
                                                        </div>
                                                      )}
                                                    </div>
                                                    {edu.education_gap ? (
                                                      <div className="middle-gap">
                                                        <div className="middle-row">
                                                          <span className="middle-lable  ">
                                                            {" "}
                                                            <SwapVertIcon /> Gap{" "}
                                                          </span>
                                                          <spam>-</spam>
                                                          <span className="middle-value">
                                                            <b>{edu.education_gap < 12 ? edu.education_gap.toString() + " Months" : (edu.education_gap / 12).toFixed(2).toString() + " years"}</b>
                                                          </span>
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </Typography>
                                                );
                                              })
                                            ) : (
                                              <Typography className="educationai">
                                                <center>Education Insights not shared in CV</center>
                                              </Typography>
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                          {stepform === "jd" && (
                            <>
                              <div className="card ">
                                <div className=" card-body ">
                                  <div className=" job-description-cv   aicv-height ">
                                    {/* {jobDescription} */}
                                    {/* <Editor editorState={editorState2} readOnly={true} /> */}
                                    <DraftEditor editorState={editorState2} readOnly />
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          {stepform === "CV" && (
                            <div className="card cv-analysis-detail" style={{ overflow: "scroll" }}>
                              <div className=" card-body ">
                                <div className="CV-action aicv-height">
                                  <Document onLoadSuccess={onDocumentLoadSuccess} file={signedUrlCV}>
                                    {Array.from(new Array(numPages), (el, index) => (
                                      <Page className="page" width={900} renderTextLayer={false} key={`${el}page_${index + 1}`} pageNumber={index + 1} />
                                    ))}
                                  </Document>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="redirectsignin">
                            {localStorage.getItem("google_token") ? (
                              <button
                                disabled={loading}
                                onClick={() => {
                                  window.open(process.env.REACT_APP_SELECTRIGHT_URL + "/signin/" + localStorage.getItem("google_token"), "_self");
                                }}
                                type="button"
                                className="btn btn-secondry"
                              >
                                {loading ? "Loading..." : "Ready to level up from basic CV analysis? Discover advanced insights at SelectRight.ai Here"}
                              </button>
                            ) : (
                              <button
                                disabled={loading}
                                onClick={() => {
                                  setIsDataSet("login");
                                  login();
                                }}
                                type="button"
                                className="btn btn-secondry"
                              >
                                {loading ? "Loading..." : "Ready to level up from basic CV analysis? Discover advanced insights at SelectRight.ai Here"}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="cvanalysis">
                        <Lottie animationData={Screening} loop={true} className="ctalottie" />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="ailoader">
                    <div className="card">
                      <div className="card-body">
                        <div className="ai-image">
                          <h5>AI Analysis...</h5>
                          <img src={aiimage} alt="AI" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="jobdescription-form">
              <SwipeableDrawer
                anchor="bottom"
                open={isOpen}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                PaperProps={{
                  style: {
                    width: "70%", // Adjust the width as per your requirement
                    margin: "auto",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  },
                }}
              >
                <div className="jobdescription-detail">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group ">
                          <label className="form-label">Job Title </label>
                          <FormControl fullWidth className=" bg-white ">
                            <TextField
                              type="text"
                              placeholder="Enter Job Title "
                              value={job_title}
                              onChange={(e) => {
                                setJobTitle(e.target.value);
                              }}
                            />
                          </FormControl>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group ">
                          <label className="form-label d-flex align-items-center gap-2 flex-grow-1  ">
                            Job Description
                            <a className=" " onClick={descriptionsuggestion} disabled={loading} style={{ cursor: "pointer" }}>
                              {loading ? (
                                <span className="d-flex align-items-center gap-1">
                                  <img src={jobdescriptionanimation} height={20} alt="JD" /> Generating.....
                                </span>
                              ) : (
                                <>
                                  <span className="d-flex align-items-center gap-2">
                                    <IconButton aria-label="comment">
                                      {" "}
                                      <img src={jobdescription} height={16} alt="JD" />
                                    </IconButton>
                                    <span>Click To Auto Generate Job Description </span>
                                  </span>
                                </>
                              )}
                            </a>
                          </label>

                          {/* <FormControl fullWidth className=" bg-white ">
                            <TextField

                              rows={5}
                              multiline
                              placeholder='Enter Job Description '
                              value={description}
                              onChange={(e) => { setDescription(e.target.value) }}
                            />
                          </FormControl> */}
                          <FormControl fullWidth className="bg-white">
                            {/* Replace TextField with Draft.js Editor */}
                            <Editor
                              editorState={editorState}
                              onEditorStateChange={handleEditorChange}
                              placeholder="Write Your Job Description Here"
                              toolbar={{
                                options: ["inline", "blockType", "fontSize", "fontFamily", "list", "textAlign", "colorPicker", "link", "embedded", "remove", "history"],
                              }}
                            />
                          </FormControl>
                          {/* <Editor
                                 editorState={editorState}
                                 toolbarClassName="toolbarclassName="
                                 wrapperClassName="demo-wrapper"
                                 editorClassName="demo-editor"
                                 onEditorStateChange={handleEditorChange}
                                //  readOnly={props.isViewOnly}
                                 placeholder="Write Your Job Description Here"
                                 //onTab={onHandleKeyBindings}
                                 toolbar={{
                                    image: {
                                       urlEnabled: true,
                                       uploadEnabled: true,
                                       uploadCallback: uploadImageCallBack,
                                       previewImage: true,
                                       alt: { present: true, mandatory: true }
                                    }
                                 }}
                              /> */}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <div className="d-flex align-items-center justify-content-between">
                            <label>
                              Skills required
                              {/* <span className='text-danger'>*</span> */}
                            </label>
                          </div>
                          <FormControl fullWidth>
                            <TagsInput value={skills} name="fruits" placeHolder="Enter skill and press enter" onChange={setSkills} />
                          </FormControl>
                          <div className="suggetion-box">
                            <div className="suggetion-title">
                              <span>
                                Skill Suggestion - <small className="text-success">(Add-ons)</small>{" "}
                              </span>
                            </div>
                            <div className="suggetion-skills">
                              {skillsRequired.map((skillN) => {
                                return (
                                  <Chip
                                    label={skillN}
                                    onClick={() => handleChipClick(skillN)}
                                    color={skills.includes(skillN) ? "success" : "primary"}
                                    icon={skills.includes(skillN) ? <CheckIcon /> : null}
                                  />
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group  text-center">
                          <button type="button" className="btn btn-blue" onClick={toggleDrawer(false)}>
                            {" "}
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwipeableDrawer>
              <Dialog maxWidth="xxl" fullWidth open={openaidescription} onClose={handleClosedescription} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className=" ">
                <DialogTitle className="d-flex  align-items-center justify-content-between pt-2 pb-2">
                  Generate With AI
                  <IconButton edge="start" color="inherit" onClick={handleClosedescription} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent dividers className="descriptiondilog">
                  <div className="row    ">
                    <div className="col-md-12">
                      <div className="form-group mb-1">
                        <label className="d-flex align-items-center gap-1">
                          <small className="text-muted">Job Title </small> <span>-</span>
                          <h5>({job_title} ) </h5>
                        </label>
                        {/* <div className="suggetion-box p-0">
                <div className="suggetion-title">
                <span>Title Suggestion   </span>
                </div>
                <div className="suggetion-skills pt-2 pb-2">
                <Chip
                label="React Js"
                size='small'
                onClick={handleClicksuggestion}
                color={isSuccess ? 'success' : 'primary'}
                icon={
                isLoading ? (
                <CircularProgress size={20} />
                ) : isSuccess ? (
                <CheckIcon />
                ) : null
                }
                />
                <Chip
                label="Node Js"
                size='small'
                onClick={handleClicksuggestion}
                color={isSuccess ? 'success' : 'primary'}
                icon={
                isLoading ? (
                <CircularProgress size={20} />
                ) : isSuccess ? (
                <CheckIcon />
                ) : null
                }
                />
                </div>
                </div> */}
                      </div>
                    </div>
                    <div className="col-md-12">
                      {/* <div className="form-group"  >
                <label className='d-flex align-items-center gap-1'>
                <small className='text-muted'>Department   </small> <span>-</span>
                <h5>(  Development )  </h5>
                </label>
                </div> */}
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label> Enter the rough details of the job description </label>
                        <FormControl fullWidth>
                          <TextField multiline rows={2} onChange={(e) => setSuggestedText(e.target.value)} placeholder="  " />
                        </FormControl>
                      </div>
                    </div>
                    <div className="col-md-12 ">
                      <div className="form-group">
                        <button
                          onClick={() => {
                            localStorage.getItem("google_token") ? generateJD1() : login();
                            setIsDataSet("generateJD");
                          }}
                          disabled={job_desc_loading}
                          title="Click on Generate to analyze description based on suggestion"
                          type="button"
                          className="btn btn-primary"
                        >
                          {job_desc_loading ? "Generating..." : "Generate"}
                        </button>
                      </div>
                    </div>
                    <div className="generated-jd">
                      <div className="aijd" style={{ position: "relative" }}>
                        {job_desc_loading ? (
                          <div className="page-loader">
                            <div className="inner-loader m-0">
                              <img src={pageloader} style={{ width: "70px" }} alt="Loading" />
                              <span>Loading...</span>
                            </div>
                          </div>
                        ) : (
                          <Editor
                            editorState={tempEditorState}
                            toolbarClassName="toolbarclassName="
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            onEditorStateChange={handleTempEditorChange}
                            placeholder="Write Your Job Description Here"
                            toolbar={{
                              options: ["inline", "blockType", "fontSize", "fontFamily", "list", "textAlign", "colorPicker", "link", "embedded", "remove", "history"],
                            }}
                            //onTab={onHandleKeyBindings}
                          />
                        )}
                      </div>
                      <div className="jd-Suggestion-wrapper">
                        {jobDescriptionJD2 ? (
                          <div className="jd-Suggestion-list">
                            <div className="jd-Suggestion-headlist bg-primary2">
                              <AutoAwesomeIcon />
                              <div>
                                <h5> Elevate Your Job Description by filling following AI-Driven Insights - </h5>
                                <small className="text-dark">(add-ons in generated description)</small>
                              </div>
                            </div>
                            <div className="refreshjd ">
                              <ul>
                                <SwapHorizOutlinedIcon className="text-muted" />
                                <button type="button" className="btn btn-secondry    " onClick={handleRefreshJDAnalysis}>
                                  refresh JD analysis
                                  <RefreshIcon style={{ fontSize: "18px" }} />
                                </button>
                              </ul>
                            </div>
                            <div className="jd-Suggestion-body">
                              {!suggestion_loading && (
                                <div className="addparentclass">
                                  <div className="suggested-inner-list">
                                    <label>
                                      Job Title
                                      <div class="invitecount">( {jobCount}/5 )</div>
                                    </label>
                                    <div className={isEditJobPosition || jobPosition === "" ? "suggested-row bg-inverse-danger" : "suggested-row bg-inverse-success"}>
                                      <div className="suggested-answer-content">
                                        <div class="question_header_content">
                                          <label>1</label>
                                        </div>
                                        <div className="jd-Suggestion-name">
                                          <p className="question_essence mb-0">Job Position </p>
                                          {isEditJobPosition ? (
                                            <>
                                              <div className="d-flex gap-2 submitsuggection">
                                                <div className="answertype mt-2  ">
                                                  <TextField
                                                    size="small"
                                                    multiline
                                                    value={jobPosition}
                                                    onChange={(e) => {
                                                      setJobPosition(e.target.value);
                                                    }}
                                                    onBlur={(e) => {
                                                      setIsEditJobPosition(false);
                                                    }}
                                                    fullWidth
                                                    autoFocus
                                                    className=" bg-white"
                                                  />
                                                </div>

                                                <div className="suuggection-save">
                                                  <Tooltip className="blue-tooltip " title="Save" placement="top" arrow>
                                                    <a href="#" className="saveico text-success">
                                                      <CheckIcon />
                                                    </a>
                                                  </Tooltip>
                                                  <Tooltip className="blue-tooltip " title="Cancle" placement="top" arrow>
                                                    <a
                                                      onClick={() => {
                                                        setJobPosition(jobDescriptionJD2.job_title.job_position);
                                                      }}
                                                      href="#"
                                                      className="saveico text-danger"
                                                    >
                                                      <CloseIcon />
                                                    </a>
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <div
                                              onClick={() => {
                                                setIsEditJobPosition(true);
                                              }}
                                              className="response-ans"
                                            >
                                              <div className="default-list">
                                                <span className="normal-text">{jobPosition}</span>
                                              </div>

                                              <div className="jd-edit">
                                                <Tooltip className="blue-tooltip " title="Edit " placement="top" arrow>
                                                  <EditIcon />
                                                </Tooltip>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className={isEditSeniority || seniority === "" ? "suggested-row bg-inverse-danger" : "suggested-row bg-inverse-success"}>
                                      <div className="suggested-answer-content">
                                        <div class="question_header_content">
                                          <label>2</label>
                                        </div>
                                        <div className="jd-Suggestion-name">
                                          <p className="question_essence mb-0">Level Of Seniority </p>
                                          {isEditSeniority ? (
                                            <>
                                              <div className="d-flex gap-2 submitsuggection">
                                                <div className="answertype mt-2  ">
                                                  <TextField
                                                    size="small"
                                                    multiline
                                                    value={seniority}
                                                    onChange={(e) => {
                                                      setSeniority(e.target.value);
                                                    }}
                                                    onBlur={(e) => {
                                                      setIsEditSeniority(false);
                                                    }}
                                                    fullWidth
                                                    autoFocus
                                                    className=" bg-white"
                                                  />
                                                </div>

                                                <div className="suuggection-save">
                                                  <Tooltip className="blue-tooltip " title="Save" placement="top" arrow>
                                                    <a href="#" className="saveico text-success">
                                                      <CheckIcon />
                                                    </a>
                                                  </Tooltip>
                                                  <Tooltip className="blue-tooltip " title="Cancle" placement="top" arrow>
                                                    <a href="#" className="saveico text-danger">
                                                      <CloseIcon />
                                                    </a>
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <div
                                              onClick={() => {
                                                setIsEditSeniority(true);
                                              }}
                                              className="response-ans"
                                            >
                                              <div className="default-list">
                                                <span className="normal-text">{seniority}</span>
                                              </div>

                                              <div className="jd-edit">
                                                <Tooltip className="blue-tooltip " title="Edit " placement="top" arrow>
                                                  <EditIcon />
                                                </Tooltip>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className={isEditDepartment || jDepartment === "" ? "suggested-row bg-inverse-danger" : "suggested-row bg-inverse-success"}>
                                      <div className="suggested-answer-content">
                                        <div class="question_header_content">
                                          <label>3</label>
                                        </div>
                                        <div className="jd-Suggestion-name">
                                          <p className="question_essence mb-0">Department </p>
                                          {isEditDepartment ? (
                                            <>
                                              <div className="d-flex gap-2 submitsuggection">
                                                <div className="answertype mt-2  ">
                                                  <TextField
                                                    size="small"
                                                    multiline
                                                    value={jDepartment}
                                                    onChange={(e) => {
                                                      setJDepartment(e.target.value);
                                                    }}
                                                    onBlur={(e) => {
                                                      setIsEditDepartment(false);
                                                    }}
                                                    fullWidth
                                                    autoFocus
                                                    className=" bg-white"
                                                  />
                                                </div>

                                                <div className="suuggection-save">
                                                  <Tooltip className="blue-tooltip " title="Save" placement="top" arrow>
                                                    <a href="#" className="saveico text-success">
                                                      <CheckIcon />
                                                    </a>
                                                  </Tooltip>
                                                  <Tooltip className="blue-tooltip " title="Cancle" placement="top" arrow>
                                                    <a href="#" className="saveico text-danger">
                                                      <CloseIcon />
                                                    </a>
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <div
                                              onClick={() => {
                                                setIsEditDepartment(true);
                                              }}
                                              className="response-ans"
                                            >
                                              <div className="default-list">
                                                <span className="normal-text">{jDepartment}</span>
                                              </div>

                                              <div className="jd-edit">
                                                <Tooltip className="blue-tooltip " title="Edit " placement="top" arrow>
                                                  <EditIcon />
                                                </Tooltip>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className={isEditSpeciality || jSpeciality === "" ? "suggested-row bg-inverse-danger" : "suggested-row bg-inverse-success"}>
                                      <div className="suggested-answer-content">
                                        <div class="question_header_content">
                                          <label>4</label>
                                        </div>
                                        <div className="jd-Suggestion-name">
                                          <p className="question_essence mb-0">Speciality </p>
                                          {isEditSpeciality ? (
                                            <>
                                              <div className="d-flex gap-2 submitsuggection">
                                                <div className="answertype mt-2  ">
                                                  <TextField
                                                    size="small"
                                                    multiline
                                                    value={jSpeciality}
                                                    onChange={(e) => {
                                                      setJSpeciality(e.target.value);
                                                    }}
                                                    onBlur={(e) => {
                                                      setIsEditSpeciality(false);
                                                    }}
                                                    fullWidth
                                                    autoFocus
                                                    className=" bg-white"
                                                  />
                                                </div>

                                                <div className="suuggection-save">
                                                  <Tooltip className="blue-tooltip " title="Save" placement="top" arrow>
                                                    <a href="#" className="saveico text-success">
                                                      <CheckIcon />
                                                    </a>
                                                  </Tooltip>
                                                  <Tooltip className="blue-tooltip " title="Cancle" placement="top" arrow>
                                                    <a href="#" className="saveico text-danger">
                                                      <CloseIcon />
                                                    </a>
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <div
                                              onClick={() => {
                                                setIsEditSpeciality(true);
                                              }}
                                              className="response-ans"
                                            >
                                              <div className="default-list">
                                                <span className="normal-text">{jSpeciality}</span>
                                              </div>

                                              <div className="jd-edit">
                                                <Tooltip className="blue-tooltip " title="Edit " placement="top" arrow>
                                                  <EditIcon />
                                                </Tooltip>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className={isEditLocation || jLocation === "" ? "suggested-row bg-inverse-danger" : "suggested-row bg-inverse-success"}>
                                      <div className="suggested-answer-content">
                                        <div class="question_header_content">
                                          <label>5</label>
                                        </div>
                                        <div className="jd-Suggestion-name">
                                          <p className="question_essence mb-0">Location </p>
                                          {isEditLocation ? (
                                            <>
                                              <div className="d-flex gap-2 submitsuggection">
                                                <div className="answertype mt-2  ">
                                                  <TextField
                                                    size="small"
                                                    multiline
                                                    value={jLocation}
                                                    onChange={(e) => {
                                                      setJLocation(e.target.value);
                                                    }}
                                                    onBlur={(e) => {
                                                      setIsEditLocation(false);
                                                    }}
                                                    fullWidth
                                                    autoFocus
                                                    className=" bg-white"
                                                  />
                                                </div>

                                                <div className="suuggection-save">
                                                  <Tooltip className="blue-tooltip " title="Save" placement="top" arrow>
                                                    <a href="#" className="saveico text-success">
                                                      <CheckIcon />
                                                    </a>
                                                  </Tooltip>
                                                  <Tooltip className="blue-tooltip " title="Cancle" placement="top" arrow>
                                                    <a href="#" className="saveico text-danger">
                                                      <CloseIcon />
                                                    </a>
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <div
                                              onClick={() => {
                                                setIsEditLocation(true);
                                              }}
                                              className="response-ans"
                                            >
                                              <div className="default-list">
                                                <span className="normal-text">{jLocation}</span>
                                              </div>

                                              <div className="jd-edit">
                                                <Tooltip className="blue-tooltip " title="Edit " placement="top" arrow>
                                                  <EditIcon />
                                                </Tooltip>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="suggested-inner-list">
                                    <label>
                                      Company
                                      <div class="invitecount">( {companyCount}/5 )</div>
                                    </label>
                                    <div className={isEditJOverview || jOverview === "" ? "suggested-row bg-inverse-danger" : "suggested-row bg-inverse-success"}>
                                      <div className="suggested-answer-content">
                                        <div class="question_header_content">
                                          <label>1</label>
                                        </div>
                                        <div className="jd-Suggestion-name">
                                          <p className="question_essence mb-0">Overview </p>
                                          {isEditJOverview ? (
                                            <>
                                              <div className="d-flex gap-2 submitsuggection">
                                                <div className="answertype mt-2  ">
                                                  <TextField
                                                    size="small"
                                                    multiline
                                                    value={jOverview}
                                                    onChange={(e) => {
                                                      setJOverview(e.target.value);
                                                    }}
                                                    onBlur={(e) => {
                                                      setIsEditJOverview(false);
                                                    }}
                                                    fullWidth
                                                    autoFocus
                                                    className=" bg-white"
                                                  />
                                                </div>

                                                <div className="suuggection-save">
                                                  <Tooltip className="blue-tooltip " title="Save" placement="top" arrow>
                                                    <a href="#" className="saveico text-success">
                                                      <CheckIcon />
                                                    </a>
                                                  </Tooltip>
                                                  <Tooltip className="blue-tooltip " title="Cancle" placement="top" arrow>
                                                    <a href="#" className="saveico text-danger">
                                                      <CloseIcon />
                                                    </a>
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <div
                                              onClick={() => {
                                                setIsEditJOverview(true);
                                              }}
                                              className="response-ans"
                                            >
                                              <div className="default-list">
                                                <span className="normal-text">{jOverview}</span>
                                              </div>

                                              <div className="jd-edit">
                                                <Tooltip className="blue-tooltip " title="Edit " placement="top" arrow>
                                                  <EditIcon />
                                                </Tooltip>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className={isEditJBusiness || jBusiness === "" ? "suggested-row bg-inverse-danger" : "suggested-row bg-inverse-success"}>
                                      <div className="suggested-answer-content">
                                        <div class="question_header_content">
                                          <label>2</label>
                                        </div>
                                        <div className="jd-Suggestion-name">
                                          <p className="question_essence mb-0">Scope of business </p>
                                          {isEditJBusiness ? (
                                            <>
                                              <div className="d-flex gap-2 submitsuggection">
                                                <div className="answertype mt-2  ">
                                                  <TextField
                                                    size="small"
                                                    multiline
                                                    value={jBusiness}
                                                    onChange={(e) => {
                                                      setJBusiness(e.target.value);
                                                    }}
                                                    onBlur={(e) => {
                                                      setIsEditJBusiness(false);
                                                    }}
                                                    fullWidth
                                                    autoFocus
                                                    className=" bg-white"
                                                  />
                                                </div>

                                                <div className="suuggection-save">
                                                  <Tooltip className="blue-tooltip " title="Save" placement="top" arrow>
                                                    <a href="#" className="saveico text-success">
                                                      <CheckIcon />
                                                    </a>
                                                  </Tooltip>
                                                  <Tooltip className="blue-tooltip " title="Cancle" placement="top" arrow>
                                                    <a href="#" className="saveico text-danger">
                                                      <CloseIcon />
                                                    </a>
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <div
                                              onClick={() => {
                                                setIsEditJBusiness(true);
                                              }}
                                              className="response-ans"
                                            >
                                              <div className="default-list">
                                                <span className="normal-text">{jBusiness}</span>
                                              </div>

                                              <div className="jd-edit">
                                                <Tooltip className="blue-tooltip " title="Edit " placement="top" arrow>
                                                  <EditIcon />
                                                </Tooltip>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className={isEditJStructure || jStructure === "" ? "suggested-row bg-inverse-danger" : "suggested-row bg-inverse-success"}>
                                      <div className="suggested-answer-content">
                                        <div class="question_header_content">
                                          <label>3</label>
                                        </div>
                                        <div className="jd-Suggestion-name">
                                          <p className="question_essence mb-0">Organization structure </p>
                                          {isEditJStructure ? (
                                            <>
                                              <div className="d-flex gap-2 submitsuggection">
                                                <div className="answertype mt-2  ">
                                                  <TextField
                                                    size="small"
                                                    multiline
                                                    value={jStructure}
                                                    onChange={(e) => {
                                                      setJStructure(e.target.value);
                                                    }}
                                                    onBlur={(e) => {
                                                      setIsEditJStructure(false);
                                                    }}
                                                    fullWidth
                                                    autoFocus
                                                    className=" bg-white"
                                                  />
                                                </div>

                                                <div className="suuggection-save">
                                                  <Tooltip className="blue-tooltip " title="Save" placement="top" arrow>
                                                    <a href="#" className="saveico text-success">
                                                      <CheckIcon />
                                                    </a>
                                                  </Tooltip>
                                                  <Tooltip className="blue-tooltip " title="Cancle" placement="top" arrow>
                                                    <a href="#" className="saveico text-danger">
                                                      <CloseIcon />
                                                    </a>
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <div
                                              onClick={() => {
                                                setIsEditJStructure(true);
                                              }}
                                              className="response-ans"
                                            >
                                              <div className="default-list">
                                                <span className="normal-text">{jStructure}</span>
                                              </div>

                                              <div className="jd-edit">
                                                <Tooltip className="blue-tooltip " title="Edit " placement="top" arrow>
                                                  <EditIcon />
                                                </Tooltip>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className={isEditJCompanySize || jCompanySize === "" ? "suggested-row bg-inverse-danger" : "suggested-row bg-inverse-success"}>
                                      <div className="suggested-answer-content">
                                        <div class="question_header_content">
                                          <label>4</label>
                                        </div>
                                        <div className="jd-Suggestion-name">
                                          <p className="question_essence mb-0">Company Size </p>
                                          {isEditJCompanySize ? (
                                            <>
                                              <div className="d-flex gap-2 submitsuggection">
                                                <div className="answertype mt-2  ">
                                                  <TextField
                                                    size="small"
                                                    multiline
                                                    value={jCompanySize}
                                                    onChange={(e) => {
                                                      setJCompanySize(e.target.value);
                                                    }}
                                                    onBlur={(e) => {
                                                      setIsEditJCompanySize(false);
                                                    }}
                                                    fullWidth
                                                    autoFocus
                                                    className=" bg-white"
                                                  />
                                                </div>

                                                <div className="suuggection-save">
                                                  <Tooltip className="blue-tooltip " title="Save" placement="top" arrow>
                                                    <a href="#" className="saveico text-success">
                                                      <CheckIcon />
                                                    </a>
                                                  </Tooltip>
                                                  <Tooltip className="blue-tooltip " title="Cancle" placement="top" arrow>
                                                    <a href="#" className="saveico text-danger">
                                                      <CloseIcon />
                                                    </a>
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <div
                                              onClick={() => {
                                                setIsEditJCompanySize(true);
                                              }}
                                              className="response-ans"
                                            >
                                              <div className="default-list">
                                                <span className="normal-text">{jCompanySize}</span>
                                              </div>

                                              <div className="jd-edit">
                                                <Tooltip className="blue-tooltip " title="Edit " placement="top" arrow>
                                                  <EditIcon />
                                                </Tooltip>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className={isEditJAwards || jAwards === "" ? "suggested-row bg-inverse-danger" : "suggested-row bg-inverse-success"}>
                                      <div className="suggested-answer-content">
                                        <div class="question_header_content">
                                          <label>5</label>
                                        </div>
                                        <div className="jd-Suggestion-name">
                                          <p className="question_essence mb-0">Recognition and awards </p>
                                          {isEditJAwards ? (
                                            <>
                                              <div className="d-flex gap-2 submitsuggection">
                                                <div className="answertype mt-2  ">
                                                  <TextField
                                                    size="small"
                                                    multiline
                                                    value={jAwards}
                                                    onChange={(e) => {
                                                      setJAwards(e.target.value);
                                                    }}
                                                    onBlur={(e) => {
                                                      setIsEditJAwards(false);
                                                    }}
                                                    fullWidth
                                                    autoFocus
                                                    className=" bg-white"
                                                  />
                                                </div>

                                                <div className="suuggection-save">
                                                  <Tooltip className="blue-tooltip " title="Save" placement="top" arrow>
                                                    <a href="#" className="saveico text-success">
                                                      <CheckIcon />
                                                    </a>
                                                  </Tooltip>
                                                  <Tooltip className="blue-tooltip " title="Cancle" placement="top" arrow>
                                                    <a href="#" className="saveico text-danger">
                                                      <CloseIcon />
                                                    </a>
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <div
                                              onClick={() => {
                                                setIsEditJAwards(true);
                                              }}
                                              className="response-ans"
                                            >
                                              <div className="default-list">
                                                <span className="normal-text">{jAwards}</span>
                                              </div>

                                              <div className="jd-edit">
                                                <Tooltip className="blue-tooltip " title="Edit " placement="top" arrow>
                                                  <EditIcon />
                                                </Tooltip>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="suggested-inner-list">
                                    <label>
                                      Roles & Responsibilities
                                      <div class="invitecount">( {rolesCount}/6 )</div>
                                    </label>
                                    <div className={isEditPrimaryDuties || jPrimaryDuties.length === 0 ? "suggested-row bg-inverse-danger" : "suggested-row bg-inverse-success"}>
                                      <div className="suggested-answer-content">
                                        <div class="question_header_content">
                                          <label>1</label>
                                        </div>
                                        <div className="jd-Suggestion-name">
                                          <p className="question_essence mb-0">Primary Duties </p>
                                          {isEditPrimaryDuties ? (
                                            <>
                                              <div className="d-flex gap-2 submitsuggection">
                                                <div className="jd-list   mt-2">
                                                  {jPrimaryDuties.map((c, index) => (
                                                    <div className="drop-option-row align-items-center" key={index}>
                                                      <div className="dropdown-option gap-1">
                                                        <div className="icon copy ">
                                                          <a href="#" onClick={() => primaryDutiesRemove(index)} className="text-danger">
                                                            <i class="las la-trash-alt" style={{ fontSize: "18px" }}></i>
                                                          </a>
                                                        </div>
                                                        <div className="dropdown-field">
                                                          <TextField
                                                            size="small"
                                                            type="text"
                                                            value={c}
                                                            onChange={(e) => {
                                                              setJPrimaryDuties((prevArr) => {
                                                                const result = [...prevArr];
                                                                result[index] = e.target.value;
                                                                return result;
                                                              });
                                                            }}
                                                            multiline
                                                            placeholder="List 1"
                                                            className=" bg-white"
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ))}
                                                  <div className="add-btn">
                                                    <Link href="#" className="btn btn-white  " onClick={primaryDutiesAddMore}>
                                                      <i className="las la-plus" /> Add More List
                                                    </Link>
                                                  </div>
                                                </div>

                                                <div className="suuggection-save">
                                                  <Tooltip
                                                    onClick={() => {
                                                      setIsEditPrimaryDuties(false);
                                                    }}
                                                    className="blue-tooltip "
                                                    title="Save"
                                                    placement="top"
                                                    arrow
                                                  >
                                                    <a href="#" className="saveico text-success">
                                                      <CheckIcon />
                                                    </a>
                                                  </Tooltip>
                                                  <Tooltip className="blue-tooltip " title="Cancle" placement="top" arrow>
                                                    <a
                                                      onClick={() => {
                                                        setIsEditPrimaryDuties(false);
                                                      }}
                                                      href="#"
                                                      className="saveico text-danger"
                                                    >
                                                      <CloseIcon />
                                                    </a>
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <div
                                              onClick={() => {
                                                setIsEditPrimaryDuties(true);
                                              }}
                                              className="response-ans"
                                            >
                                              <div className="default-list jd-listing">
                                                <ul>
                                                  {jPrimaryDuties.map((c, index) => (
                                                    <li>{c}</li>
                                                  ))}
                                                </ul>
                                              </div>

                                              <div className="jd-edit">
                                                <Tooltip className="blue-tooltip " title="Edit " placement="top" arrow>
                                                  <EditIcon />
                                                </Tooltip>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className={isEditJDescisionMaking || jDescisionMaking.length === 0 ? "suggested-row bg-inverse-danger" : "suggested-row bg-inverse-success"}>
                                      <div className="suggested-answer-content">
                                        <div class="question_header_content">
                                          <label>2</label>
                                        </div>
                                        <div className="jd-Suggestion-name">
                                          <p className="question_essence mb-0">Descision making responsibilities </p>
                                          {isEditJDescisionMaking ? (
                                            <>
                                              <div className="d-flex gap-2 submitsuggection">
                                                <div className="jd-list   mt-2">
                                                  {jDescisionMaking.map((c, index) => (
                                                    <div className="drop-option-row align-items-center" key={index}>
                                                      <div className="dropdown-option gap-1">
                                                        <div className="icon copy ">
                                                          <a href="#" onClick={() => descisionMakingRemove(index)} className="text-danger">
                                                            <i class="las la-trash-alt" style={{ fontSize: "18px" }}></i>
                                                          </a>
                                                        </div>
                                                        <div className="dropdown-field">
                                                          <TextField
                                                            size="small"
                                                            type="text"
                                                            value={c}
                                                            onChange={(e) => {
                                                              setJDescisionMaking((prevArr) => {
                                                                const result = [...prevArr];
                                                                result[index] = e.target.value;
                                                                return result;
                                                              });
                                                            }}
                                                            multiline
                                                            placeholder="List 1"
                                                            className=" bg-white"
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ))}
                                                  <div className="add-btn">
                                                    <Link href="#" className="btn btn-white  " onClick={descisionMakingAddMore}>
                                                      <i className="las la-plus" /> Add More List
                                                    </Link>
                                                  </div>
                                                </div>

                                                <div className="suuggection-save">
                                                  <Tooltip
                                                    onClick={() => {
                                                      setIsEditJDescisionMaking(false);
                                                    }}
                                                    className="blue-tooltip "
                                                    title="Save"
                                                    placement="top"
                                                    arrow
                                                  >
                                                    <a href="#" className="saveico text-success">
                                                      <CheckIcon />
                                                    </a>
                                                  </Tooltip>
                                                  <Tooltip className="blue-tooltip " title="Cancle" placement="top" arrow>
                                                    <a
                                                      onClick={() => {
                                                        setIsEditJDescisionMaking(false);
                                                      }}
                                                      href="#"
                                                      className="saveico text-danger"
                                                    >
                                                      <CloseIcon />
                                                    </a>
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <div
                                              onClick={() => {
                                                setIsEditJDescisionMaking(true);
                                              }}
                                              className="response-ans"
                                            >
                                              <div className="default-list jd-listing">
                                                <ul>
                                                  {jDescisionMaking.map((c, index) => (
                                                    <li>{c}</li>
                                                  ))}
                                                </ul>
                                              </div>

                                              <div className="jd-edit">
                                                <Tooltip className="blue-tooltip " title="Edit " placement="top" arrow>
                                                  <EditIcon />
                                                </Tooltip>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className={isEditCollaborativeDuties || jCollaborativeDuties.length === 0 ? "suggested-row bg-inverse-danger" : "suggested-row bg-inverse-success"}>
                                      <div className="suggested-answer-content">
                                        <div class="question_header_content">
                                          <label>3</label>
                                        </div>
                                        <div className="jd-Suggestion-name">
                                          <p className="question_essence mb-0">Collaborative Duties </p>
                                          {isEditCollaborativeDuties ? (
                                            <>
                                              <div className="d-flex gap-2 submitsuggection">
                                                <div className="jd-list   mt-2">
                                                  {jCollaborativeDuties.map((c, index) => (
                                                    <div className="drop-option-row align-items-center" key={index}>
                                                      <div className="dropdown-option gap-1">
                                                        <div className="icon copy ">
                                                          <a href="#" onClick={() => collaborativeDutiesRemove(index)} className="text-danger">
                                                            <i class="las la-trash-alt" style={{ fontSize: "18px" }}></i>
                                                          </a>
                                                        </div>
                                                        <div className="dropdown-field">
                                                          <TextField
                                                            size="small"
                                                            type="text"
                                                            value={c}
                                                            onChange={(e) => {
                                                              setJCollaborativeDuties((prevArr) => {
                                                                const result = [...prevArr];
                                                                result[index] = e.target.value;
                                                                return result;
                                                              });
                                                            }}
                                                            multiline
                                                            placeholder="List 1"
                                                            className=" bg-white"
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ))}
                                                  <div className="add-btn">
                                                    <Link href="#" className="btn btn-white  " onClick={collaborativeDutiesAddMore}>
                                                      <i className="las la-plus" /> Add More List
                                                    </Link>
                                                  </div>
                                                </div>

                                                <div className="suuggection-save">
                                                  <Tooltip
                                                    onClick={() => {
                                                      setIsEditCollaborativeDuties(false);
                                                    }}
                                                    className="blue-tooltip "
                                                    title="Save"
                                                    placement="top"
                                                    arrow
                                                  >
                                                    <a href="#" className="saveico text-success">
                                                      <CheckIcon />
                                                    </a>
                                                  </Tooltip>
                                                  <Tooltip className="blue-tooltip " title="Cancle" placement="top" arrow>
                                                    <a
                                                      onClick={() => {
                                                        setIsEditCollaborativeDuties(false);
                                                      }}
                                                      href="#"
                                                      className="saveico text-danger"
                                                    >
                                                      <CloseIcon />
                                                    </a>
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <div
                                              onClick={() => {
                                                setIsEditCollaborativeDuties(true);
                                              }}
                                              className="response-ans"
                                            >
                                              <div className="default-list jd-listing">
                                                <ul>
                                                  {jCollaborativeDuties.map((c, index) => (
                                                    <li>{c}</li>
                                                  ))}
                                                </ul>
                                              </div>

                                              <div className="jd-edit">
                                                <Tooltip className="blue-tooltip " title="Edit " placement="top" arrow>
                                                  <EditIcon />
                                                </Tooltip>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className={isEditLeadershipDuties || jLeadershipDuties.length === 0 ? "suggested-row bg-inverse-danger" : "suggested-row bg-inverse-success"}>
                                      <div className="suggested-answer-content">
                                        <div class="question_header_content">
                                          <label>4</label>
                                        </div>
                                        <div className="jd-Suggestion-name">
                                          <p className="question_essence mb-0">Leadership Duties </p>
                                          {isEditLeadershipDuties ? (
                                            <>
                                              <div className="d-flex gap-2 submitsuggection">
                                                <div className="jd-list   mt-2">
                                                  {jLeadershipDuties.map((c, index) => (
                                                    <div className="drop-option-row align-items-center" key={index}>
                                                      <div className="dropdown-option gap-1">
                                                        <div className="icon copy ">
                                                          <a href="#" onClick={() => leadershipDutiesRemove(index)} className="text-danger">
                                                            <i class="las la-trash-alt" style={{ fontSize: "18px" }}></i>
                                                          </a>
                                                        </div>
                                                        <div className="dropdown-field">
                                                          <TextField
                                                            size="small"
                                                            type="text"
                                                            value={c}
                                                            onChange={(e) => {
                                                              setJLeadershipDuties((prevArr) => {
                                                                const result = [...prevArr];
                                                                result[index] = e.target.value;
                                                                return result;
                                                              });
                                                            }}
                                                            multiline
                                                            placeholder="List 1"
                                                            className=" bg-white"
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ))}
                                                  <div className="add-btn">
                                                    <Link href="#" className="btn btn-white  " onClick={leadershipDutiesAddMore}>
                                                      <i className="las la-plus" /> Add More List
                                                    </Link>
                                                  </div>
                                                </div>

                                                <div className="suuggection-save">
                                                  <Tooltip
                                                    onClick={() => {
                                                      setIsEditLeadershipDuties(false);
                                                    }}
                                                    className="blue-tooltip "
                                                    title="Save"
                                                    placement="top"
                                                    arrow
                                                  >
                                                    <a href="#" className="saveico text-success">
                                                      <CheckIcon />
                                                    </a>
                                                  </Tooltip>
                                                  <Tooltip className="blue-tooltip " title="Cancle" placement="top" arrow>
                                                    <a
                                                      onClick={() => {
                                                        setIsEditLeadershipDuties(false);
                                                      }}
                                                      href="#"
                                                      className="saveico text-danger"
                                                    >
                                                      <CloseIcon />
                                                    </a>
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <div
                                              onClick={() => {
                                                setIsEditLeadershipDuties(true);
                                              }}
                                              className="response-ans"
                                            >
                                              <div className="default-list jd-listing">
                                                <ul>
                                                  {jLeadershipDuties.map((c, index) => (
                                                    <li>{c}</li>
                                                  ))}
                                                </ul>
                                              </div>

                                              <div className="jd-edit">
                                                <Tooltip className="blue-tooltip " title="Edit " placement="top" arrow>
                                                  <EditIcon />
                                                </Tooltip>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className={isEditProjectRoles || jProjectRoles.length === 0 ? "suggested-row bg-inverse-danger" : "suggested-row bg-inverse-success"}>
                                      <div className="suggested-answer-content">
                                        <div class="question_header_content">
                                          <label>5</label>
                                        </div>
                                        <div className="jd-Suggestion-name">
                                          <p className="question_essence mb-0">Project Roles </p>
                                          {isEditProjectRoles ? (
                                            <>
                                              <div className="d-flex gap-2 submitsuggection">
                                                <div className="jd-list   mt-2">
                                                  {jProjectRoles.map((c, index) => (
                                                    <div className="drop-option-row align-items-center" key={index}>
                                                      <div className="dropdown-option gap-1">
                                                        <div className="icon copy ">
                                                          <a href="#" onClick={() => projectRolesRemove(index)} className="text-danger">
                                                            <i class="las la-trash-alt" style={{ fontSize: "18px" }}></i>
                                                          </a>
                                                        </div>
                                                        <div className="dropdown-field">
                                                          <TextField
                                                            size="small"
                                                            type="text"
                                                            value={c}
                                                            onChange={(e) => {
                                                              setJProjectRoles((prevArr) => {
                                                                const result = [...prevArr];
                                                                result[index] = e.target.value;
                                                                return result;
                                                              });
                                                            }}
                                                            multiline
                                                            placeholder="List 1"
                                                            className=" bg-white"
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ))}
                                                  <div className="add-btn">
                                                    <Link href="#" className="btn btn-white  " onClick={projectRolesAddMore}>
                                                      <i className="las la-plus" /> Add More List
                                                    </Link>
                                                  </div>
                                                </div>

                                                <div className="suuggection-save">
                                                  <Tooltip
                                                    onClick={() => {
                                                      setIsEditProjectRoles(false);
                                                    }}
                                                    className="blue-tooltip "
                                                    title="Save"
                                                    placement="top"
                                                    arrow
                                                  >
                                                    <a href="#" className="saveico text-success">
                                                      <CheckIcon />
                                                    </a>
                                                  </Tooltip>
                                                  <Tooltip className="blue-tooltip " title="Cancle" placement="top" arrow>
                                                    <a
                                                      onClick={() => {
                                                        setIsEditProjectRoles(false);
                                                      }}
                                                      href="#"
                                                      className="saveico text-danger"
                                                    >
                                                      <CloseIcon />
                                                    </a>
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <div
                                              onClick={() => {
                                                setIsEditProjectRoles(true);
                                              }}
                                              className="response-ans"
                                            >
                                              <div className="default-list jd-listing">
                                                <ul>
                                                  {jProjectRoles.map((c, index) => (
                                                    <li>{c}</li>
                                                  ))}
                                                </ul>
                                              </div>

                                              <div className="jd-edit">
                                                <Tooltip className="blue-tooltip " title="Edit " placement="top" arrow>
                                                  <EditIcon />
                                                </Tooltip>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className={isEditCustomerService || jCustomerService.length === 0 ? "suggested-row bg-inverse-danger" : "suggested-row bg-inverse-success"}>
                                      <div className="suggested-answer-content">
                                        <div class="question_header_content">
                                          <label>6</label>
                                        </div>
                                        <div className="jd-Suggestion-name">
                                          <p className="question_essence mb-0">Customer Service </p>
                                          {isEditCustomerService ? (
                                            <>
                                              <div className="d-flex gap-2 submitsuggection">
                                                <div className="jd-list   mt-2">
                                                  {jCustomerService.map((c, index) => (
                                                    <div className="drop-option-row align-items-center" key={index}>
                                                      <div className="dropdown-option gap-1">
                                                        <div className="icon copy ">
                                                          <a href="#" onClick={() => customerServiceRemove(index)} className="text-danger">
                                                            <i class="las la-trash-alt" style={{ fontSize: "18px" }}></i>
                                                          </a>
                                                        </div>
                                                        <div className="dropdown-field">
                                                          <TextField
                                                            size="small"
                                                            type="text"
                                                            value={c}
                                                            onChange={(e) => {
                                                              setJCustomerService((prevArr) => {
                                                                const result = [...prevArr];
                                                                result[index] = e.target.value;
                                                                return result;
                                                              });
                                                            }}
                                                            multiline
                                                            placeholder="List 1"
                                                            className=" bg-white"
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ))}
                                                  <div className="add-btn">
                                                    <Link href="#" className="btn btn-white  " onClick={customerServiceAddMore}>
                                                      <i className="las la-plus" /> Add More List
                                                    </Link>
                                                  </div>
                                                </div>

                                                <div className="suuggection-save">
                                                  <Tooltip
                                                    onClick={() => {
                                                      setIsEditCustomerService(false);
                                                    }}
                                                    className="blue-tooltip "
                                                    title="Save"
                                                    placement="top"
                                                    arrow
                                                  >
                                                    <a href="#" className="saveico text-success">
                                                      <CheckIcon />
                                                    </a>
                                                  </Tooltip>
                                                  <Tooltip className="blue-tooltip " title="Cancle" placement="top" arrow>
                                                    <a
                                                      onClick={() => {
                                                        setIsEditCustomerService(false);
                                                      }}
                                                      href="#"
                                                      className="saveico text-danger"
                                                    >
                                                      <CloseIcon />
                                                    </a>
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <div
                                              onClick={() => {
                                                setIsEditCustomerService(true);
                                              }}
                                              className="response-ans"
                                            >
                                              <div className="default-list jd-listing">
                                                <ul>
                                                  {jCustomerService.map((c, index) => (
                                                    <li>{c}</li>
                                                  ))}
                                                </ul>
                                              </div>

                                              <div className="jd-edit">
                                                <Tooltip className="blue-tooltip " title="Edit " placement="top" arrow>
                                                  <EditIcon />
                                                </Tooltip>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="suggested-inner-list">
                                    <label>
                                      Preferred Qualifications
                                      <div class="invitecount">( {qualificationsCount}/4 )</div>
                                    </label>
                                    <div className={isEditEductionalQualifications || jEducationalQualifications === "" ? "suggested-row bg-inverse-danger" : "suggested-row bg-inverse-success"}>
                                      <div className="suggested-answer-content">
                                        <div class="question_header_content">
                                          <label>1</label>
                                        </div>
                                        <div className="jd-Suggestion-name">
                                          <p className="question_essence mb-0">Educational Qualifications </p>
                                          {isEditEductionalQualifications ? (
                                            <>
                                              <div className="d-flex gap-2 submitsuggection">
                                                <div className="answertype mt-2  ">
                                                  <TextField
                                                    size="small"
                                                    multiline
                                                    value={jEducationalQualifications}
                                                    onChange={(e) => {
                                                      setJEducationalQualifications(e.target.value);
                                                    }}
                                                    onBlur={(e) => {
                                                      setIsEditEducationalQualifications(false);
                                                    }}
                                                    fullWidth
                                                    autoFocus
                                                    className=" bg-white"
                                                  />
                                                </div>

                                                <div className="suuggection-save">
                                                  <Tooltip className="blue-tooltip " title="Save" placement="top" arrow>
                                                    <a href="#" className="saveico text-success">
                                                      <CheckIcon />
                                                    </a>
                                                  </Tooltip>
                                                  <Tooltip className="blue-tooltip " title="Cancle" placement="top" arrow>
                                                    <a
                                                      onClick={() => {
                                                        setJEducationalQualifications(jobDescriptionJD2.preferred_qualifications.education_qualification);
                                                      }}
                                                      href="#"
                                                      className="saveico text-danger"
                                                    >
                                                      <CloseIcon />
                                                    </a>
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <div
                                              onClick={() => {
                                                setIsEditEducationalQualifications(true);
                                              }}
                                              className="response-ans"
                                            >
                                              <div className="default-list">
                                                <span className="normal-text">{jEducationalQualifications}</span>
                                              </div>

                                              <div className="jd-edit">
                                                <Tooltip className="blue-tooltip " title="Edit " placement="top" arrow>
                                                  <EditIcon />
                                                </Tooltip>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className={isEditWorkExperience || jWorkExperience === "" ? "suggested-row bg-inverse-danger" : "suggested-row bg-inverse-success"}>
                                      <div className="suggested-answer-content">
                                        <div class="question_header_content">
                                          <label>2</label>
                                        </div>
                                        <div className="jd-Suggestion-name">
                                          <p className="question_essence mb-0">Work Experience </p>
                                          {isEditWorkExperience ? (
                                            <>
                                              <div className="d-flex gap-2 submitsuggection">
                                                <div className="answertype mt-2  ">
                                                  <TextField
                                                    size="small"
                                                    multiline
                                                    value={jWorkExperience}
                                                    onChange={(e) => {
                                                      setJWorkExperience(e.target.value);
                                                    }}
                                                    onBlur={(e) => {
                                                      setIsEditWorkExperience(false);
                                                    }}
                                                    fullWidth
                                                    autoFocus
                                                    className=" bg-white"
                                                  />
                                                </div>

                                                <div className="suuggection-save">
                                                  <Tooltip className="blue-tooltip " title="Save" placement="top" arrow>
                                                    <a href="#" className="saveico text-success">
                                                      <CheckIcon />
                                                    </a>
                                                  </Tooltip>
                                                  <Tooltip className="blue-tooltip " title="Cancle" placement="top" arrow>
                                                    <a
                                                      onClick={() => {
                                                        setJWorkExperience(jobDescriptionJD2.preferred_qualifications.work_experience);
                                                      }}
                                                      href="#"
                                                      className="saveico text-danger"
                                                    >
                                                      <CloseIcon />
                                                    </a>
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <div
                                              onClick={() => {
                                                setIsEditWorkExperience(true);
                                              }}
                                              className="response-ans"
                                            >
                                              <div className="default-list">
                                                <span className="normal-text">{jWorkExperience}</span>
                                              </div>

                                              <div className="jd-edit">
                                                <Tooltip className="blue-tooltip " title="Edit " placement="top" arrow>
                                                  <EditIcon />
                                                </Tooltip>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className={isEditKnowledgeSkillsAbilities || jKnowledgeSkillsAbilities.length === 0 ? "suggested-row bg-inverse-danger" : "suggested-row bg-inverse-success"}>
                                      <div className="suggested-answer-content">
                                        <div class="question_header_content">
                                          <label>3</label>
                                        </div>
                                        <div className="jd-Suggestion-name">
                                          <p className="question_essence mb-0">Knowledge , Skills & Abilities </p>
                                          {isEditKnowledgeSkillsAbilities ? (
                                            <>
                                              <div className="d-flex gap-2 submitsuggection">
                                                <div className="jd-list   mt-2">
                                                  {jKnowledgeSkillsAbilities.map((c, index) => (
                                                    <div className="drop-option-row align-items-center" key={index}>
                                                      <div className="dropdown-option gap-1">
                                                        <div className="icon copy ">
                                                          <a href="#" onClick={() => knowledgeSkillsAbilitiesRemove(index)} className="text-danger">
                                                            <i class="las la-trash-alt" style={{ fontSize: "18px" }}></i>
                                                          </a>
                                                        </div>
                                                        <div className="dropdown-field">
                                                          <TextField
                                                            size="small"
                                                            type="text"
                                                            value={c}
                                                            onChange={(e) => {
                                                              setJKnowledgeSkillsAbilities((prevArr) => {
                                                                const result = [...prevArr];
                                                                result[index] = e.target.value;
                                                                return result;
                                                              });
                                                            }}
                                                            multiline
                                                            placeholder="List 1"
                                                            className=" bg-white"
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ))}
                                                  <div className="add-btn">
                                                    <Link href="#" className="btn btn-white  " onClick={knowledgeSkillsAbilitiesAddMore}>
                                                      <i className="las la-plus" /> Add More List
                                                    </Link>
                                                  </div>
                                                </div>

                                                <div className="suuggection-save">
                                                  <Tooltip
                                                    onClick={() => {
                                                      setIsEditKnowledgeSkillsAbilities(false);
                                                    }}
                                                    className="blue-tooltip "
                                                    title="Save"
                                                    placement="top"
                                                    arrow
                                                  >
                                                    <a href="#" className="saveico text-success">
                                                      <CheckIcon />
                                                    </a>
                                                  </Tooltip>
                                                  <Tooltip className="blue-tooltip " title="Cancle" placement="top" arrow>
                                                    <a
                                                      onClick={() => {
                                                        setIsEditKnowledgeSkillsAbilities(false);
                                                      }}
                                                      href="#"
                                                      className="saveico text-danger"
                                                    >
                                                      <CloseIcon />
                                                    </a>
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <div
                                              onClick={() => {
                                                setIsEditKnowledgeSkillsAbilities(true);
                                              }}
                                              className="response-ans"
                                            >
                                              <div className="default-list jd-listing">
                                                <ul>
                                                  {jKnowledgeSkillsAbilities.map((c, index) => (
                                                    <li>{c}</li>
                                                  ))}
                                                </ul>
                                              </div>

                                              <div className="jd-edit">
                                                <Tooltip className="blue-tooltip " title="Edit " placement="top" arrow>
                                                  <EditIcon />
                                                </Tooltip>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className={isEditOtherQualifications || jOtherQualifications.length === 0 ? "suggested-row bg-inverse-danger" : "suggested-row bg-inverse-success"}>
                                      <div className="suggested-answer-content">
                                        <div class="question_header_content">
                                          <label>4</label>
                                        </div>
                                        <div className="jd-Suggestion-name">
                                          <p className="question_essence mb-0">Other Qualifications </p>
                                          {isEditOtherQualifications ? (
                                            <>
                                              <div className="d-flex gap-2 submitsuggection">
                                                <div className="jd-list   mt-2">
                                                  {jOtherQualifications.map((c, index) => (
                                                    <div className="drop-option-row align-items-center" key={index}>
                                                      <div className="dropdown-option gap-1">
                                                        <div className="icon copy ">
                                                          <a href="#" onClick={() => otherQualificationsRemove(index)} className="text-danger">
                                                            <i class="las la-trash-alt" style={{ fontSize: "18px" }}></i>
                                                          </a>
                                                        </div>
                                                        <div className="dropdown-field">
                                                          <TextField
                                                            size="small"
                                                            type="text"
                                                            value={c}
                                                            onChange={(e) => {
                                                              setJOtherQualifications((prevArr) => {
                                                                const result = [...prevArr];
                                                                result[index] = e.target.value;
                                                                return result;
                                                              });
                                                            }}
                                                            multiline
                                                            placeholder="List 1"
                                                            className=" bg-white"
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ))}
                                                  <div className="add-btn">
                                                    <Link href="#" className="btn btn-white  " onClick={otherQualificationsAddMore}>
                                                      <i className="las la-plus" /> Add More List
                                                    </Link>
                                                  </div>
                                                </div>

                                                <div className="suuggection-save">
                                                  <Tooltip
                                                    onClick={() => {
                                                      setIsEditOtherQualifications(false);
                                                    }}
                                                    className="blue-tooltip "
                                                    title="Save"
                                                    placement="top"
                                                    arrow
                                                  >
                                                    <a href="#" className="saveico text-success">
                                                      <CheckIcon />
                                                    </a>
                                                  </Tooltip>
                                                  <Tooltip className="blue-tooltip " title="Cancle" placement="top" arrow>
                                                    <a
                                                      onClick={() => {
                                                        setIsEditOtherQualifications(false);
                                                      }}
                                                      href="#"
                                                      className="saveico text-danger"
                                                    >
                                                      <CloseIcon />
                                                    </a>
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <div
                                              onClick={() => {
                                                setIsEditOtherQualifications(true);
                                              }}
                                              className="response-ans"
                                            >
                                              <div className="default-list jd-listing">
                                                <ul>
                                                  {jOtherQualifications.map((c, index) => (
                                                    <li>{c}</li>
                                                  ))}
                                                </ul>
                                              </div>

                                              <div className="jd-edit">
                                                <Tooltip className="blue-tooltip " title="Edit " placement="top" arrow>
                                                  <EditIcon />
                                                </Tooltip>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {suggestion_loading && (
                                <div className="page-loader">
                                  <div className="inner-loader m-0">
                                    <img src={pageloader} style={{ width: "70px" }} alt="loading" />
                                    <span>Loading...</span>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="jd-Suggestion-btn text-center mb-3">
                              <button onClick={generateJD2} disabled={suggestion_loading} type="button" className="btn btn-primary  ">
                                {suggestion_loading ? "Wait.." : "Update JD"}
                              </button>
                            </div>
                          </div>
                        ) : suggestion_loading ? (
                          <div className="page-loader">
                            <div className="inner-loader m-0">
                              <img src={pageloader} style={{ width: "70px" }} alt="loading" />
                              <span>Loading...</span>
                            </div>
                          </div>
                        ) : (
                          <div className="not-fount-table " style={{ display: "block" }}>
                            <div className="not-fount-center pt-0">
                              <div class="fond-icon-content text-center">
                                <div className="Nofound-image bigimage">
                                  <img src={noimage} alt="not visible" />
                                </div>
                                <div className="no-data-info__body">
                                  <div className="col-md-12 ">
                                    <div className="form-group">
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => {
                                          getSuggestions();
                                        }}
                                      >
                                        Generate suggestions{" "}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={active}>Submit</Button>
                  {/* <Button onClick={handleClosedescription} color='error'  >
                Clear
                </Button> */}
                  <Button onClick={handleClosedescription}>Cancel</Button>
                </DialogActions>
              </Dialog>
              <Modal open={open1} onClose={handleClose1} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box className="  modal-common   " sx={commonpopup}>
                  <div className="modal-body">
                    <div className="model-content text-center">
                      <h3>Are you sure to save ? </h3>
                    </div>
                    <div className="modal-btn">
                      <button className="btn btn-success btn-block  " onClick={handleJDsubmit}>
                        Confirm
                      </button>
                      <button className="btn btn-danger btn-block  " onClick={handleClose1}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </Box>
              </Modal>
            </div>
          </div>
        </div>
      </section>
      <section className="middlebanner Transforming">
        <div className="whyit">
          <img src={why1} alt="" />
        </div>
        <div className="container">
          <div className="row">
            <div className="row justify-content-center">
              <div className="col-xl-10 col-lg-10 col-md-10 col-sm-6 col-12">
                <div className="section-title mb-0">
                  <h2 data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200px">
                    Transforming hiring into a science with AI
                  </h2>
                  <p className="mt-3" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200px">
                    Artificial intelligence (AI) has the potential to transform the hiring process into a more objective and data-driven one. By leveraging AI algorithms, recruiters and hiring
                    managers can reduce bias, speed up the hiring process, and improve the overall quality of hires. AI can automate time-consuming tasks such as initial screening interviews and
                    provide data-driven insights to inform hiring decisions. While AI can improve the hiring process, it's important to note that the human element should still be present to ensure
                    candidates are a good cultural fit for the organization.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="bookdemobtn mt-4 text-center" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200px">
                  <a href="/book-demo">
                    {" "}
                    <button className="btn  btn-blue" type="button">
                      Book A Demo
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-pedding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
              <div className="section-title" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200px">
                <h2 className>Streamlined recruitment process</h2>
                <p>SelectRight automates the recruitment process from start to finish. Our AI powered platform ensures a smooth and efficient experience for recruiters and applicants.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mt-15">
              <div className="feature-tab-info card " data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200px">
                <div className="feature-tab-head">
                  <Lottie animationData={Screening} loop={true} className="ctalottie" />
                </div>
                <div className="feature-tab-body">
                  <h3> Screening every applicant made possible with the automated system</h3>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mt-15">
              <div className="feature-tab-info card  " data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200px">
                <div className="feature-tab-head">
                  <Lottie animationData={fake} loop={true} className="ctalottie" />
                </div>
                <div className="feature-tab-body">
                  <h3>No more fake hirings - Enabled through state of art AI enabled proctoring</h3>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mt-15">
              <div className="feature-tab-info card " data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200px">
                <div className="feature-tab-head">
                  <Lottie animationData={Single} loop={true} className="ctalottie" />
                </div>
                <div className="feature-tab-body">
                  <h3> Single window applicants tracking - Track all applicants from across all platforms in one ATS</h3>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mt-15">
              <div className="feature-tab-info card " data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200px">
                <div className="feature-tab-head">
                  <img src={Eliminate} alt="" />
                </div>
                <div className="feature-tab-body">
                  <h3>Eliminate human judgement error - No more missing good candidate and inclusion of unfit candidate</h3>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mt-15">
              <div className="feature-tab-info card  " data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200px">
                <div className="feature-tab-head">
                  <img src={savetime} alt="" />
                </div>
                <div className="feature-tab-body">
                  <h3>Saving more than 80% of the recruitment time</h3>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mt-15">
              <div className="feature-tab-info card  " data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200px">
                <div className="feature-tab-head">
                  <Lottie animationData={Better} loop={true} className="ctalottie" />
                </div>
                <div className="feature-tab-body">
                  <h3>Better recruitment expense decisions enabled by MIS</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterPage />
    </div>
  );
};
